import { memo, useMemo } from "react"
import { TableBody, TableCell, TableRow } from '@material-ui/core'
import React from 'react'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Link } from 'react-router-dom';

function TableBodyUser(props) {
    const {
        userDetailsList = [],
        selectedUsers = [],
        onClickAddUser = () => { },
        revokeUser = () => { }
    } = props
    return useMemo(() => {
        return <TableBody>
            {
                userDetailsList.length ? userDetailsList.map((item, key) => {
                    const isChecked = selectedUsers.find(user => user.user_id === item.user_id) || false
                    // if (key > 5) return null
                    return <TableRow key={key}>
                        <TableCell>{key + 1}</TableCell>
                        <TableCell><Link to={`/view_user_details/${item.user_id}`} className="link-color-black">{item.name || '-'}</Link></TableCell>
                        <TableCell>{item.email || '-'}</TableCell>
                        <TableCell>{item.phone || '-'}</TableCell>
                        <TableCell>{item.address || '-'}</TableCell>
                        <TableCell>{item.ip || '-'}</TableCell>
                        <TableCell>{item.revenue || ''}</TableCell>
                        <TableCell>
                            <input type="checkbox" checked={!!isChecked} onChange={() => onClickAddUser(item)} />
                        </TableCell>
                        <TableCell>
                            <div className="user-details-actions">
                                <a href={`https://backend.fronicmedia.com/api/generateAgreementForAdmin?user_id=${item.user_id}`} target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()} className="download-agreement-btn"><CloudDownloadIcon />Agreement</a>
                                {item.revoked === 0 ?
                                    <button className="revoke-action" onClick={e => revokeUser(e, item.user_id, 1)}>Revoke</button> :
                                    <button className="unrevoke-action" onClick={e => revokeUser(e, item.user_id, 0)}>Unrevoke</button>}
                            </div>
                        </TableCell>
                    </TableRow>
                }) : <TableRow>
                    <TableCell colSpan="8" align="center">No User Found.</TableCell>
                </TableRow>
            }
        </TableBody>
    }, [userDetailsList, selectedUsers])
}

export default memo(TableBodyUser)
