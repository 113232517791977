import React, { useMemo } from 'react'
import { Grid, Button, Table, TableHead, TableBody, TableCell, TableRow, TableContainer, Paper } from "@material-ui/core"
import Pagination from '@material-ui/lab/Pagination';
import moment from "moment"
import DeleteIcon from '@material-ui/icons/Delete';

function Broadcast(props) {

    const {
        broadcasts = [],
        totalPages = 0,
        onPageChange = () => { },
        filters = {},
        setBroadcastOpen = () => { },
        onDeleteNotification = () => { },
    } = props

    return useMemo(() => (
        <Grid container>
            <Grid item xs={12}>
                <div style={{ display: "flex", justifyContent: "end", marginBottom: '20px' }}>
                    <Button color="primary" variant="contained" onClick={() => setBroadcastOpen(prev => !prev)}>Broadcast</Button>
                </div>
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow style={{ background: "linear-gradient(90deg, rgba(19,153,121,1) 0%, rgba(18,180,161,1) 45%, rgba(5,150,179,1) 100%)" }}>
                                <TableCell className="color-white" >S.No</TableCell>
                                {/* <TableCell className="color-white">Broadcast Id</TableCell> */}
                                <TableCell className="color-white">Notification</TableCell>
                                <TableCell className="color-white">Sent On</TableCell>
                                <TableCell className="color-white">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                broadcasts.length ? broadcasts.map((item, index) => {
                                    return <TableRow key={index}>
                                        <TableCell>{((filters.page - 1) * filters.limit) + (index + 1)}</TableCell>
                                        {/* <TableCell>{item.broadcast_id}</TableCell> */}
                                        <TableCell style={{ maxWidth: 900 }} >{item.notification}</TableCell>
                                        <TableCell>{moment(item.created_at).format('DD/MM/yyyy HH:mm')}</TableCell>
                                        <TableCell><DeleteIcon style={{ color: "red" }} onClick={() => onDeleteNotification(item.broadcast_id)} /></TableCell>
                                    </TableRow>
                                }) : null
                            }
                        </TableBody>
                    </Table>
                    <Pagination count={totalPages} showFirstButton showLastButton onChange={onPageChange} page={filters.page || 0} />
                </TableContainer>
            </Grid>
        </Grid>
    ), [broadcasts, totalPages, filters])
}

export default Broadcast
