export default function LoginFormValidate(data) {

    let errors = {}
    let isValid = true;
    if (!data.email) {
        errors.email = 'Email is Required'
        isValid = false
    }

    if (!data.password) {
        errors.password = 'Password is Required'
        isValid = false
    }

    return { errors, isValid }
}
