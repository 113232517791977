import React, { useEffect, useState, useCallback } from 'react'
import { Grid, Box, Paper, Table, TableBody, TableCell, TableRow, TableHead, TableContainer, withStyles, TextField } from "@material-ui/core"
import * as actions from "../Common/actions"
import { toastError } from '../../utils'
import Pagination from '@material-ui/lab/Pagination';
import _ from "lodash"

const CssTextField = withStyles({
    root: {
        // background: "transparent",
        fontFamily: "'DM Sans', sans-serif",
        color: "#fff",
        width: "100%",
        '& label': {
            fontFamily: "'DM Sans', sans-serif",
            // color: '#10baa8',
            color: "white"
        },
        '& label.Mui-focused': {
            // color: '#10baa8',
            color: "white"
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'red',
        },
        '& .MuiOutlinedInput-root': {
            // '& fieldset': {
            // borderColor: 'white',
            // },
            // '&:hover fieldset': {
            // borderColor: 'white',
            // },
            '&.Mui-focused fieldset': {
                borderColor: '#10baa8',
                borderWidth: "1px"
            },
        },
    },
})(TextField);

function Artists(props) {

    const [artists, setArtists] = useState([])
    const [filters, setFilters] = useState({
        sort: 0,
        limit: 50,
        offset: 0,
        page: 1,
    })
    const [count, setCount] = useState(0)
    const [search, setSearch] = useState('')
    // const delaySearch = useCallback(_.debounce(filters => onGetAllArtists({ ...filters }), 500), []);

    useEffect(() => {
        onGetAllArtists(filters)
    }, [filters])

    const onPageChange = (event, page) => {
        const offset = (page - 1) * filters.limit
        setFilters({ ...filters, page, offset })
    }

    const searchReleases = (value) => {
        setSearch(value)
        // delaySearch({ ...filters, search: value })
    }

    const onGetAllArtists = (filters) => {
        props.setLoader(true)
        actions.getAllArtistNamesAdmin(filters).then(res => {
            props.setLoader(false)
            setArtists(res.body)
            setCount(res.count)
        }).catch(err => {
            props.setLoader(false)
            // toastError(err.message)
        })
    }
    // console.log({ ...filters, search }, 'filters')
    const totalPages = Math.ceil(Number(count) / 50)

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6} sm={7}>
                        <CssTextField
                            type="text"
                            variant="outlined"
                            size="small"
                            onChange={(e) => searchReleases(e.target.value)}
                            value={search}
                            placeholder="Search..."
                        />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Box>
                            <h2 style={{ float: "right", marginBottom: 20 }}>Total Artists: {count}</h2>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table className="universal-table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>User Name</TableCell>
                                <TableCell>Artist Name</TableCell>
                                <TableCell>Apple ID</TableCell>
                                <TableCell>Spotify ID</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                Array.isArray(artists) && artists.length ? artists.map((item, key) => {
                                    return <TableRow key={key}>
                                        <TableCell>{((filters.page - 1) * filters.limit) + (key + 1)}</TableCell>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{item.artist_name}</TableCell>
                                        <TableCell>{item.apple_id}</TableCell>
                                        <TableCell>{item.spotify_id}</TableCell>
                                    </TableRow>
                                }) : <TableRow>
                                    <TableCell colSpan="5" align="center">No Artist Found.</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                    <Pagination count={totalPages} showFirstButton showLastButton onChange={onPageChange} page={filters.page || 0} />
                </TableContainer>
            </Grid>

        </Grid>
    )
}

export default Artists
