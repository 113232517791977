export const API_URL = 'https://backend.fronicmedia.com'

export const releaseFilters = [
    // {
    //     label: 'In-Review',
    //     value: 'in_review'
    // },
    {
        label: 'Approved',
        value: 'approved'
    },
    {
        label: 'Rejected',
        value: 'rejected'
    },
    {
            label: 'TakenDown',
            value: 'takendown'   
    }
]

export const ticketOptions = [
    {
        label: 'All',
        value: 'all'
    },
    {
        label: 'Open',
        value: 'open'
    },
    {
        label: 'Close',
        value: 'close'
    },

]