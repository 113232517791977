import React, { useEffect, useState } from 'react'
import { Grid, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Paper, Box } from "@material-ui/core"
import * as actions from "../Common/actions"
import { toastError, toastSuccess } from '../../utils'
import ConfirmModal from '../Common/CommonModal';

function Payout(props) {

    const [payouts, setPayouts] = useState([])
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState({
        user_id: '',
        request_id: ''
    })

    useEffect(() => {
        getAllPayouts()
    }, [])

    const getAllPayouts = () => {
        props.setLoader(true)
        actions.getWithdrawRequestAdmin().then(res => {
            props.setLoader(false)
            setPayouts(res.body)
        }).catch(err => {
            toastError(err.message)
            props.setLoader(false)
        })
    }

    const onClickSettle = (item) => {
        setSelectedItem({ user_id: item.user_id, request_id: item.request_id })
        setConfirmOpen(true)
    }

    const onConfirmSettle = () => {
        props.setLoader(true)
        actions.sentMoneyByAdmin(selectedItem).then(res => {
            props.setLoader(false)
            toastSuccess(res.message)
            setConfirmOpen(false)
            getAllPayouts()
        }).catch(err => {
            props.setLoader(false)
            toastError(err.message)
            setConfirmOpen(false)
        })
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box>
                    <TableContainer component={Paper}>
                        <Table className="universal-table">
                            <TableHead>
                                <TableRow style={{ background: "linear-gradient(90deg, rgba(19,153,121,1) 0%, rgba(18,180,161,1) 45%, rgba(5,150,179,1) 100%)" }}>
                                    <TableCell className="color-white">No.</TableCell>
                                    <TableCell className="color-white">Name</TableCell>
                                    <TableCell className="color-white">Email</TableCell>
                                    <TableCell className="color-white">Phone</TableCell>
                                    <TableCell className="color-white">Revenue</TableCell>
                                    <TableCell className="color-white">Action</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    payouts.length ? payouts.map((item, key) => {
                                        return <TableRow key={key}>
                                            <TableCell>{key + 1}</TableCell>
                                            <TableCell>{item.name || '-'}</TableCell>
                                            <TableCell>{item.email || '-'}</TableCell>
                                            <TableCell>{item.phone || '-'}</TableCell>
                                            <TableCell>{item.revenue || '-'}</TableCell>
                                            <TableCell><button className="settle-payout" onClick={() => onClickSettle(item)}>Settle</button></TableCell>
                                        </TableRow>
                                    }) : <TableRow>
                                        <TableCell colSpan="8" align="center">No Request Found.</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Grid>
            <ConfirmModal
                message={`Are you sure you want to settle?`}
                open={confirmOpen}
                title={`Confirmation`}
                onSubmitConfirm={onConfirmSettle}
                handleClose={() => setConfirmOpen(false)}
            >

            </ConfirmModal>
        </Grid>
    )
}

export default Payout
