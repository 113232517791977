import React, { useEffect, useState } from 'react'
import { Grid, Box, TextField, Button } from "@material-ui/core"
import SaveIcon from '@material-ui/icons/Save';
import { getVideoLinks, updateHomepageVideo } from "./actions"
import { toastError, toastSuccess } from '../../utils';

export default function Homepage(props) {

    const [videoLinks, setVideoLinks] = useState([])

    useEffect(() => {
        getVideoLinks().then(res => {
            setVideoLinks(res.body)
        }).catch(err => toastError(err.message))
    }, [])

    const onChangeHandler = (value, index) => {
        let newVideoLinks = [...videoLinks]
        newVideoLinks[index].video_link = value;
        setVideoLinks(newVideoLinks)
    }

    const onVideoLinkUpdate = (data) => {
        updateHomepageVideo(data).then(res => {
            toastSuccess(res.message)
        }).catch(err => {
            toastError(err.message)
        })
    }

    return (
        <Grid container className="homepage-container">
            <Grid item xs={12} sm={10} md={9} lg={7} className="homepage-container-item">
                {videoLinks.length ? videoLinks.map((video, key) => <Box className="item-box" key={key}>
                    <h2>Video {key + 1}</h2>
                    <TextField
                        id="outlined-basic"
                        placeholder={`Enter Video ${key + 1} link`}
                        variant="outlined"
                        fullWidth
                        value={video.video_link}
                        onChange={e => onChangeHandler(e.target.value, key)}
                    />
                    <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        startIcon={<SaveIcon />}
                        onClick={() => onVideoLinkUpdate({ video_id: video.video_id, video_link: video.video_link })}
                    >Save</Button>
                </Box>) : null}
            </Grid>
        </Grid>
    )
}