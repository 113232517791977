import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import logo from "../../assets/img/Fronic_logo_black.png"
import { useHistory } from "react-router-dom"
import { saveObject, toastError } from '../../utils';
import LoginFormValidate from './LoginFormValidate';
import { CircularProgress, Hidden } from '@material-ui/core';
import Loader from 'react-loader-advanced';
import { adminLogin } from "./actions"

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        // backgroundImage: 'url(https://source.unsplash.com/random)',
        // backgroundRepeat: 'no-repeat',
        // backgroundColor:
        //     theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
        width: '100%',
        height: '100vh'
    },
    innerImage: {
        width: '100%',
        height: "100%",
        objectFit: "cover"
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Login() {
    const classes = useStyles();
    const history = useHistory();
    const [user, setUser] = useState({
        email: '',
        password: ''
    })
    const [errors, setErrors] = useState({})
    const [imageLoader, setImageLoader] = useState(true);
    const [loginLoader, setLoginLoader] = useState(false)

    // useEffect(() => {
    //     const image = 'https://source.unsplash.com/random';
    //     console.log(image, "https://source.unsplash.com/random")

    //     axios.get('https://source.unsplash.com/random').then(res => {
    //         console.log(res)
    //     }).catch(err => {

    //     })

    // }, [])

    const onChangeHandler = ({ target: { value = '', name = '' } }) => {
        setUser({ ...user, [name]: value })
        setErrors({ ...errors, [name]: '' })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (loginLoader) return
        const { errors, isValid } = LoginFormValidate(user)
        setErrors(errors);
        if (isValid) {
            setLoginLoader(true)
            adminLogin(user).then((res) => {
                setLoginLoader(false)
                saveObject('auth', res.token)
                return history.push('/')
            }).catch((err) => {
                setLoginLoader(false)
                toastError(err.message || 'Something went wrong')

            })
        }
    }

    const LoaderStyle = {
        height: "100%",
        overflow: "hidden"
    }

    return (
        <Grid container component="main" className={`${classes.root} login-container`}>
            <CssBaseline />
            <Hidden only={['xs']}>
                <Grid item xs={false} sm={4} md={7} className={classes.image}>
                    <Loader className="main-loader" style={imageLoader ? LoaderStyle : null} message={<CircularProgress size={50} style={{ color: "white" }} />} show={imageLoader}>
                        <img src="https://source.unsplash.com/random" alt='' onLoad={() => setImageLoader(false)} className={classes.innerImage} />
                    </Loader>
                </Grid>
            </Hidden>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>

                    {/* <LockOutlinedIcon /> */}
                    <div className="login-logo-div">
                        <img src={logo} alt="logo" />
                    </div>
                    <form className={`${classes.form} form-container`} onSubmit={onSubmit} noValidate>
                        <TextField
                            type="text"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            error={errors && errors.email ? !!errors.email : !!''}
                            value={user.email}
                            onChange={onChangeHandler}
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            error={errors && errors.password ? !!errors.password : !!''}
                            value={user.password}
                            onChange={onChangeHandler}
                            autoComplete="current-password"
                        />
                        {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            style={{ height: 50 }}
                            onClick={onSubmit}
                        >
                            {loginLoader ? <CircularProgress size={25} style={{ color: "white" }} /> : 'Login'}
                        </Button>
                        <Grid container>
                            {/* <Grid item xs>
                                <Link style={{ float: "right" }} href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid> */}
                            {/* <Grid item>
                                <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid> */}
                        </Grid>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}