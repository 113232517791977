import { apiGet, apiPost } from "../../utils"

export function getAllbroadcastNotificationAdminAPI(data) {
    return apiGet('/api/getAllbroadcastNotificationAdmin', data)
}

export function getNotificationsForUserAPI(data) {
    return apiGet('/api/getNotificationsForUser', data)
}

export function broadcastNotificationAdminAPI(data) {
    return apiPost('/api/broadcastNotificationAdmin', data)
}

export function getAllSpecificNotificationAdminAPI(data) {
    return apiGet('/api/getAllSpecificNotificationAdmin', data)
}

export function getSpecificNotificationByIdAdminByIdAPI(data) {
    return apiGet(`/api/getSpecificNotificationByIdAdmin?notification_id=${data}`)
}

export function deleteNotificationByIdAdminAPI(data) {
    return apiPost(`/api/deleteNotificationByIdAdmin`, data)
}

// export function addNewsAPI(data) {
//     return apiPost('/api/addNews', data)
// }
