import React, { useEffect, useState, useCallback } from 'react'
import * as actions from "../Common/actions"
import { Grid, Paper, Box, Table, TableRow, TableCell, TableContainer, TableHead, TableBody, withStyles, TextField } from "@material-ui/core"
import moment from "moment"
import { Link, useHistory } from "react-router-dom"
import _ from "lodash";
import SelectCMP from "react-select"
import { ticketOptions } from "../../constants"

const CssTextField = withStyles({
    root: {
        // background: "transparent",
        fontFamily: "'DM Sans', sans-serif",
        color: "#fff",
        width: "100%",
        '& label': {
            fontFamily: "'DM Sans', sans-serif",
            // color: '#10baa8',
            color: "white"
        },
        '& label.Mui-focused': {
            // color: '#10baa8',
            color: "white"
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'red',
        },
        '& .MuiOutlinedInput-root': {
            // '& fieldset': {
            // borderColor: 'white',
            // },
            // '&:hover fieldset': {
            // borderColor: 'white',
            // },
            '&.Mui-focused fieldset': {
                borderColor: '#10baa8',
                borderWidth: "1px"
            },
        },
    },
})(TextField);

export default function Tickets(props) {
    const history = useHistory()
    const [tickets, setTickets] = useState([])
    const [search, setSearch] = useState('')
    const [selectedTicketType, setSelectedTicketType] = useState({ label: 'All', value: 'all' })
    const delaySearch = useCallback(_.debounce(filters => onGetAllTickets(filters), 500), []);

    useEffect(() => {
        onGetAllTickets({ sort: selectedTicketType.value })
    }, [selectedTicketType])

    const onGetAllTickets = (data = {}) => {
        props.setLoader(true)
        actions.getTicketListForAdmin(data).then(res => {
            props.setLoader(false)
            setTickets(res.body)
        }).catch(err => {
            props.setLoader(false)

        })
    }

    const searchReleases = (value) => {
        setSearch(value)
        delaySearch({ search: value })
    }


    const onClickUserTicket = (id) => {
        history.push(`view_tickets/${id}`)
    }

    return (
        <Grid container>
            <Grid item xs={12} sm={8} md={10}>
                <Box mt={2} mb={2}>
                    <CssTextField
                        type="text"
                        variant="outlined"
                        size="small"
                        onChange={(e) => searchReleases(e.target.value)}
                        value={search}
                        placeholder="Search..."
                    />
                </Box>
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
                <Box mt={2} mb={2} pl={2}>
                    <SelectCMP
                        options={ticketOptions}
                        value={selectedTicketType}
                        onChange={e => setSelectedTicketType(e)}
                    />
                </Box>
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table className="universal-table">
                        <TableHead>
                            <TableRow style={{ background: "linear-gradient(90deg, rgba(19,153,121,1) 0%, rgba(18,180,161,1) 45%, rgba(5,150,179,1) 100%)" }}>
                                <TableCell className="color-white">No.</TableCell>
                                <TableCell className="color-white">Ticket No.</TableCell>
                                <TableCell className="color-white" >Name</TableCell>
                                <TableCell className="color-white" >Email</TableCell>
                                <TableCell className="color-white" >Reason</TableCell>
                                <TableCell className="color-white" >Date</TableCell>
                                <TableCell className="color-white" >Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                tickets.length ? tickets.map((item, key) => {
                                    return <TableRow key={key}>
                                        <TableCell>{key + 1}</TableCell>
                                        <TableCell><Link to={`/view_tickets/${item.ticket_id}`} className="link-color-black">{item.ticket_id || '-'}</Link></TableCell>
                                        <TableCell>{item.user_name}</TableCell>
                                        <TableCell>{item.user_email}</TableCell>
                                        <TableCell>{item.reason}</TableCell>
                                        <TableCell>{moment(item.date).format('DD/MM/yyyy')}</TableCell>
                                        <TableCell>{item.status === 0 ? <span>Open</span> : <span style={{ color: "green" }}>Closed</span>}</TableCell>
                                    </TableRow>
                                }) : <TableRow>
                                    <TableCell align="center" colSpan="7">No Report Found.</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}
