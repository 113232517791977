import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TextField,
  withStyles,
  Tooltip,
  Box,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import * as actions from "../Releases/actions";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CancelIcon from "@material-ui/icons/Cancel";
import _ from "lodash";
import AddBoxOutlined from "@material-ui/icons/AddBoxOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Instagram from "@material-ui/icons/Instagram";
import Facebook from "@material-ui/icons/Facebook";
import { toastError, toastSuccess } from "../../utils";
import CustomShowModal from "../Common/customShowModal";

const CssTextField = withStyles({
  root: {
    // background: "transparent",
    fontFamily: "'DM Sans', sans-serif",
    color: "#fff",
    width: "100%",
    "& label": {
      fontFamily: "'DM Sans', sans-serif",
      // color: '#10baa8',
      color: "white",
    },
    "& label.Mui-focused": {
      // color: '#10baa8',
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "red",
    },
    "& .MuiOutlinedInput-root": {
      // '& fieldset': {
      // borderColor: 'white',
      // },
      // '&:hover fieldset': {
      // borderColor: 'white',
      // },
      "&.Mui-focused fieldset": {
        borderColor: "#10baa8",
        borderWidth: "1px",
      },
    },
  },
})(TextField);

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export default function Linking(props) {
  const [pListPitchNew, setPListPitchNew] = useState([]);
  const [pListPitchOld, setPListPitchOld] = useState([]);
  const [actionsModal, setActionsModal] = useState(false);
  const [detailsData, setDetailsData] = useState();
  const [viewDetailsModal, setViewDetailsModal] = useState(false);
  const [actionId, setActionId] = useState();
  const [count, setCount] = useState(0);

  const [filters, setFilters] = useState({
    limit: 50,
    offset: 0,
    page: 1,
    search: "",
  });

  const delaySearch = useCallback(
    _.debounce((filters) => onGetPListPitchOld(filters), 500),
    []
  );

  useEffect(() => {
    onGetPListPitchOld(filters);
    setTimeout(() => {
      props.setLoader(false);
    }, 5000);
  }, []);

  const onGetPListPitchOld = (filters) => {
    props.setLoader(true);
    actions
      .getPitchOldAdmin({ ...filters })
      .then((res) => {
        props.setLoader(false);
        setPListPitchOld(res.body);
        setCount(res.total);
      })
      .catch((err) => {
        props.setLoader(false);
        toastError(err.message);
      });
  };

  const onPageChange = (event, page) => {
    const offset = (page - 1) * filters.limit;
    const newFilters = { ...filters, page, offset };
    setFilters(newFilters);
    onGetPListPitchOld(newFilters);
  };

  const onChangeSearch = (e) => {
    const newFilters = { ...filters, search: e.target.value };
    setFilters(newFilters);
    delaySearch(newFilters);
  };

  const onGetPListPitchNew = () => {
    props.setLoader(true);
    actions
      .getPitchRequestAdmin()
      .then((res) => {
        setPListPitchNew(res.body);
        props.setLoader(false);
        // setCount(res.total)
      })
      .catch((err) => {
        props.setLoader(false);
      });
  };

  useEffect(() => {
    onGetPListPitchNew();
  }, []);

  let totalPages = Math.ceil(Number(count) / filters.limit);

  const handleViewDetails = (data) => {
    setDetailsData(data);
    setViewDetailsModal(true);
  };

  const handleActionOnClick = (item) => {
    setActionsModal(true);
    setActionId(item);
  };

  const onConfirmAcceptModal = (status) => {
    props.setLoader(true);
    actions
      .updatePitchStatusAdmin({ status: status, pitch_id: actionId})
      .then((res) => {
        setActionsModal(false);
        props.setLoader(false);
        onGetPListPitchNew();
        onGetPListPitchOld(filters);
      })
      .catch((err) => {
        props.setLoader(false);
      });
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <p style={{ fontWeight: 500 }}>Review Required</p>

          <Box mt={2}>
            <CssTextField
              type="text"
              variant="outlined"
              size="small"
              onChange={onChangeSearch}
              value={filters.search}
              placeholder="Search..."
            />
          </Box>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
          <TableContainer component={Paper}>
            <Table className="universal-table">
              <TableHead>
                <TableRow
                  style={{
                    background:
                      "linear-gradient(90deg, rgba(19,153,121,1) 0%, rgba(18,180,161,1) 45%, rgba(5,150,179,1) 100%)",
                  }}
                >
                  <TableCell className="color-white">PId</TableCell>
                  <TableCell className="color-white">Name</TableCell>
                  <TableCell className="color-white">Email</TableCell>
                  <TableCell className="color-white">RId</TableCell>
                  <TableCell className="color-white">Release</TableCell>
                  <TableCell className="color-white">Audio</TableCell>
                  <TableCell className="color-white">View Details</TableCell>
                  <TableCell className="color-white">Date</TableCell>
                  <TableCell className="color-white">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pListPitchNew.length ? (
                  pListPitchNew.map((item, key) => {
                    return (
                      <TableRow key={key}>
                        <TableCell>{item.pitch_id}</TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.email}</TableCell>
                        <TableCell>{item.release_id}</TableCell>
                        <TableCell>{item.release_title}</TableCell>
                        <TableCell>{item.audio_title}</TableCell>
                        <TableCell>
                              <span
                                style={{
                                  color: "blue",
                                  cursor: "pointer",
                                  fontWeight: 500,
                                }}
                                onClick={() => handleViewDetails(item)}
                              >
                                View Pitch
                              </span>
                            </TableCell>
                        <TableCell>{item.date}</TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          <AddBoxOutlined
                            onClick={() => handleActionOnClick(item.pitch_id)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan="9" align="center">
                      No Requests Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <h2
            style={{
              fontWeight: 500,
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            Profile Linking History
          </h2>
          <TableContainer component={Paper}>
            <Table className="universal-table">
              <TableHead>
                <TableRow
                  style={{
                    background:
                      "linear-gradient(90deg, rgba(19,153,121,1) 0%, rgba(18,180,161,1) 45%, rgba(5,150,179,1) 100%)",
                  }}
                >
                  <TableCell className="color-white">PId</TableCell>
                  <TableCell className="color-white">Name</TableCell>
                  <TableCell className="color-white">Email</TableCell>
                  <TableCell className="color-white">RId</TableCell>
                  <TableCell className="color-white">Release</TableCell>
                  <TableCell className="color-white">Audio</TableCell>
                  <TableCell className="color-white">View Details</TableCell>
                  <TableCell className="color-white">Date</TableCell>
                  <TableCell className="color-white">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pListPitchOld.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item.pitch_id}</TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>{item.release_id}</TableCell>
                      <TableCell>{item.release_title}</TableCell>
                      <TableCell>{item.audio_title}</TableCell>
                      <TableCell>
                              <span
                                style={{
                                  color: "blue",
                                  cursor: "pointer",
                                  fontWeight: 500,
                                }}
                                onClick={() => handleViewDetails(item)}
                              >
                                View Pitch
                              </span>
                            </TableCell>
                      <TableCell>{item.date}</TableCell>
                      <TableCell align="center">
                        <p onClick={(e) => e.stopPropagation()}>
                          {item.status === "rejected" ? (
                            <LightTooltip title="Rejected">
                              <CancelIcon style={{ color: "red" }} />
                            </LightTooltip>
                          ) : (
                            <LightTooltip title="Approved">
                              <CheckCircleIcon style={{ color: "green" }} />
                            </LightTooltip>
                          )}
                        </p>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Pagination
              count={totalPages}
              showFirstButton
              showLastButton
              onChange={onPageChange}
              page={filters.page || 0}
            />
          </TableContainer>
        </Grid>
      </Grid>
      <CustomShowModal
        handleClose={() => setActionsModal(false)}
        title={"What's your action ?"}
        open={actionsModal}
        maxWidth="sm"
      >
        <div className="aceept-reject-button">
          <button className="accept" onClick={() => onConfirmAcceptModal("approved")}>
            Accept
          </button>
          <button className="reject" onClick={() => onConfirmAcceptModal("rejected")}>
            Reject
          </button>
        </div>
      </CustomShowModal>
      <CustomShowModal open={viewDetailsModal} handleClose={() => setViewDetailsModal(false)} maxWidth="sm" title={detailsData?.release_title || "Details"}>
        <div>
        <p style={{ wordBreak: "break-all", paddingBottom: "5px" }}>
            <strong>User Pitch:</strong>
            <br />
            {detailsData?.why_pitch}
          </p>

          <p style={{ wordBreak: "break-all", paddingBottom: "5px" }}>
            <strong>Quick Pitch:</strong>
            <br />
            {detailsData?.quick_pitch}
          </p>

          <p style={{ wordBreak: "break-all", paddingBottom: "5px" }}>
            <strong>Additional Information:</strong>
            <br />
            {detailsData?.additional_info}
          </p>
          <p style={{ wordBreak: "break-all", paddingBottom: "5px" }}>
            <strong>Artwork Assets:</strong>
            <br />
            <a href={detailsData?.artwork_asset}>{detailsData?.artwork_asset}</a>
          </p>

          <p style={{ wordBreak: "break-all", paddingBottom: "5px" }}>
            <strong>Additional Assets:</strong>
            <br />
            <a href={detailsData?.additional_assets}>{detailsData?.additional_assets}</a>
          </p>
          <strong>Moods:</strong>
          <ul>
            {detailsData?.moods.map((item) => {
              return <li>{item}</li>;
            })}
          </ul>
          <strong>Press Releases:</strong>
          <ul>
            {detailsData?.press_release.map((item) => {
              return <li>{item}</li>;
            })}
          </ul>
          <strong>Social Media Links:</strong>
          <ul>
            {detailsData?.social_media.map((item) => {
              return <li>{item}</li>;
            })}
          </ul>
        </div>
      </CustomShowModal>
    </div>
  );
}
