import { getNewsAPI, addNewsAPI } from "./api"

export function getNews() {
    return new Promise((resolve, reject) => {
        getNewsAPI().then(res => resolve(res)).catch(err => reject(err))
    })
}

export function addNews(data) {
    return new Promise((resolve, reject) => {
        addNewsAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}