import React, { useState, useEffect } from 'react'
import { Grid, Paper, Table, TableRow, TableCell, TableContainer, TableHead, TableBody } from "@material-ui/core"
import * as commonAction from "../../containers/Common/actions"
import moment from "moment"
import { toastSuccess } from '../../utils'

export default function ReportsRequests(props) {

    const [reports, setReports] = useState([])

    useEffect(() => {
        onGetReportRequests()
    }, [])

    const onGetReportRequests = () => {
        props.setLoader(true)
        commonAction.getReportRequestAdmin().then(res => {
            props.setLoader(false)
            setReports(res.body)
        }).catch(err => {
            props.setLoader(false)
        })
    }

    const onConfirmDelivered = (id) => {
        props.setLoader(true)
        commonAction.confirmReportDeliveryAdmin({ report_id: id }).then(res => {
            props.setLoader(false)
            toastSuccess(res.message)
            onGetReportRequests()
        }).catch(err => {
            props.setLoader(false)

        })
    }

    return (
        <Grid container>
            {/* <Grid item xs={12}>
                <h2>Report Request</h2>
            </Grid> */}

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table className="universal-table">
                        <TableHead>
                            <TableRow style={{ background: "linear-gradient(90deg, rgba(19,153,121,1) 0%, rgba(18,180,161,1) 45%, rgba(5,150,179,1) 100%)" }}>
                                <TableCell className="color-white">No.</TableCell>
                                <TableCell className="color-white" >Name</TableCell>
                                <TableCell className="color-white" >Email</TableCell>
                                <TableCell className="color-white" >Start Date</TableCell>
                                <TableCell className="color-white" >End Date</TableCell>
                                <TableCell className="color-white" >Store</TableCell>
                                <TableCell className="color-white" >Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                reports.length ? reports.map((item, key) => {
                                    return <TableRow key={key}>
                                        <TableCell>{key + 1}</TableCell>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{item.email}</TableCell>
                                        <TableCell>{moment(Number(item.start_date)).format('DD/MM/yyyy')}</TableCell>
                                        <TableCell>{moment(Number(item.end_date)).format('DD/MM/yyyy')}</TableCell>
                                        <TableCell>{item.store}</TableCell>
                                        <TableCell><div className="admin-delivered"><span onClick={() => onConfirmDelivered(item.report_id)}>Deliver</span></div></TableCell>
                                    </TableRow>
                                }) : <TableRow>
                                        <TableCell align="center" colSpan="7">No Report Found.</TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}
