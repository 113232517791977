import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Button,
} from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import * as actions from "./actions";
import * as commonActions from "../Common/actions";
import { API_URL, releaseFilters } from "../../constants";
import moment from "moment";
import CustomShowModal from "../Common/customShowModal";
import ViewSong from "./ViewSong";
import ConfirmModal from "../Common/ConfirmModal";
import CustomAddModal from "../Common/CustomAddModal";
import { toastSuccess, toastError } from "../../utils";
import SelectCMP from "react-select";

function ViewReleases(props) {
  const params = useParams();
  const history = useHistory();

  const [release, setRelease] = useState({});
  const [openSong, setOpenSong] = useState(false);
  const [selectedSong, setSelectedSong] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [openReject, setOpenReject] = useState(false);
  const [reasonText, setReasonText] = useState("");
  const [showSongModal, setShowSongModal] = useState(false);
  const [rejectOptions, setRejectOptions] = useState([]);
  const [recipeFilePreview, setRecipeFilePreview] = useState(null);
  const [updatedPoster, setUpdatedPoster] = useState("");

  const onFileHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 1572864) {
        return toastError("File more than 1.5 MB is not allowed.");
      }
      props.setLoader(true);
      commonActions
        .upload(file)
        .then((res) => {
          props.setLoader(false);
          setUpdatedPoster(res.body.filename);
          previewsFile(file);
          toastSuccess("Artwork uploaded successfully.");
        })
        .catch((err) => {
          props.setLoader(false);
          toastError(err.message || "Something went wrong");
        });
    }
  };

  const onDelete = (item) => {
    props.setLoader(true);
    actions
      .deleteFileFromServer({ fileLink: item })
      .then((res) => {
        window.location.reload(false);
      })
      .catch((err) => {
        props.setLoader(false);
      });
  }

  const onSaveUpdatedArtwork = () => {
    commonActions.updateAlbumArtworkAdmin({ release_id: params.id, album_artwork: updatedPoster || '' }).then(res => {
        toastSuccess(res.message || 'Updated successfully');
        setRecipeFilePreview(null);
        onGetRelease();
    }).catch(err => {
        toastError(err.message)
    })
}

  const previewsFile = (file) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        //convert
        setRecipeFilePreview(reader.result);
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    onGetRelease();
    onGetRejectOptions();
  }, []);

  const onGetRejectOptions = () => {
    actions.rejectReasonsAdmin().then((res) => {
      setRejectOptions(
        Array.isArray(res.body)
          ? res.body.map((item) => ({ label: item.reason, value: item.reason }))
          : []
      );
    });
  };

  const onGetRelease = () => {
    props.setLoader(true);
    actions
      .getNewReleaseByIdAdmin(params.id)
      .then((res) => {
        props.setLoader(false);
        setRelease(res.body);
      })
      .catch((err) => {
        props.setLoader(false);
      });
  };

  const onOpenModal = (item) => {
    setSelectedSong(item);
    setOpenSong(true);
  };

  const onConfirmAcceptModal = () => {
    props.setLoader(true);
    actions
      .changeReleaseStatusAdmin({ status: "approved", release_id: selectedId })
      .then((res) => {
        setOpenConfirm(false);
        props.setLoader(false);
        onGetRelease();
      })
      .catch((err) => {
        props.setLoader(false);
      });
  };

  const onClickAccept = (id) => {
    setSelectedId(id);
    setOpenConfirm(true);
  };

  const onClickReject = (id) => {
    setSelectedId(id);
    setOpenReject(true);
  };

  const onSubmitReject = () => {
    props.setLoader(true);
    actions
      .changeReleaseStatusAdmin({
        release_id: selectedId,
        status: "rejected",
        reason: reasonText,
      })
      .then((res) => {
        setOpenReject(false);
        props.setLoader(false);
        setShowSongModal(true);
        onGetRelease();
      })
      .catch(() => {
        props.setLoader(false);
      });
  };

  const onRejectASong = (audio_id) => {
    props.setLoader(true);
    actions
      .rejectReleaseAudioAdmin({ audio_id })
      .then((res) => {
        props.setLoader(false);
        toastSuccess(res.message);
        onGetRelease();
      })
      .catch((err) => {
        props.setLoader(false);
      });
  };

  const onDeleteRejectedRelease = (release_id) => {
    props.setLoader(true);
    actions
      .deleteRejectedRelease({ release_id })
      .then((res) => {
        props.setLoader(false);
        toastSuccess(res.message);
        return history.push("/release/rejected");
      })
      .catch((err) => {
        props.setLoader(false);
      });
  };

  const keyPressedReason = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      return;
    } else if (e.key === "Enter") {
      e.preventDefault();
      onSubmitReject();
    } else return;
  };

  const onTakeDownRelease = (release_id) => {
    props.setLoader(true);
    actions
      .changeReleaseStatusAdmin({ release_id, status: "takendown" })
      .then((res) => {
        props.setLoader(false);
        onGetRelease();
      })
      .catch(() => {
        props.setLoader(false);
      });
  };

  const {
    album_artwork = "",
    apple_id1 = "",
    apple_id1f = "",
    apple_id2 = "",
    apple_id2f = "",
    apple_id3 = "",
    apple_id3f = "",
    audios = [],
    c_line = "",
    crbt = "",
    featuring1 = "",
    featuring2 = "",
    featuring3 = "",
    genre = "",
    lable_name = "",
    number_of_tracks = "",
    p_line = "",
    primary_artist1 = "",
    primary_artist2 = "",
    primary_artist3 = "",
    release_date = "",
    release_id = "",
    release_title = "",
    release_type = "",
    spotify_id1 = "",
    spotify_id1f = "",
    spotify_id2 = "",
    spotify_id2f = "",
    spotify_id3 = "",
    spotify_id3f = "",
    status = "",
    sub_genre = "",
    upc_ean = "",
    name = "",
    email = "",
    video_platforms = "",
  } = release;

  return (
    <Grid container className="view-release-container">
      {status === "rejected" ? (
        <Grid item xs={12}>
          <Box>
            <Button
              onClick={() => onDeleteRejectedRelease(release_id)}
              color="secondary"
              variant="contained"
              style={{ textTransform: "capitalize", float: "right" }}
            >
              Delete Release
            </Button>
          </Box>
        </Grid>
      ) : status === "approved" ? (
        <Grid item xs={12}>
          <Box>
            <Button
              onClick={() => onTakeDownRelease(release_id)}
              color="secondary"
              variant="contained"
              style={{
                textTransform: "capitalize",
                float: "right",
                marginLeft: 20,
              }}
            >
              Take down Release
            </Button>
            <Button
              onClick={() => onClickReject(release_id)}
              color="secondary"
              variant="contained"
              style={{ textTransform: "capitalize", float: "right" }}
            >
              Reject Release
            </Button>
          </Box>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Paper>
          <Box mt={4} mb={2} pt={4} pb={4}>
            <Grid container className="catalog-image">
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                style={{ display: "flex", height: "310px" }}
              >
                <div
                  style={{
                    margin: "auto",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  <div className="image-div">
                    <img
                      src={recipeFilePreview || `${API_URL}/${album_artwork}`}
                      alt=""
                    />
                  </div>
                  <a
                    href={recipeFilePreview || `${API_URL}/${album_artwork}`}
                    download="hello"
                    target="_blank"
                    rel="noreferrer"
                    className="download-link"
                  >
                    Download
                  </a>
                  <div
                    style={{
                      marginTop: "25px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    className="aceept-reject-button"
                  >
                    {recipeFilePreview ? (
                      <button className="accept" onClick={onSaveUpdatedArtwork}>Save</button>
                    ) : (
                      <label class="custom-file-upload">
                        <input
                          type="file"
                          accept="image/x-png,image/gif,image/jpeg"
                          onChange={onFileHandler}
                        />
                        Update Poster
                      </label>
                    )}

            <Button
              onClick={() => onDelete(album_artwork)}
              color="secondary"
              variant="contained"
              style={{ textTransform: "capitalize", float: "right", height: '35px', marginLeft: '5px' }}
            >
              Delete
            </Button>
                  </div>
                  {status === "in_review" ? (
                    <>
                      <p style={{ marginTop: 45 }}>
                        Do you want to accept or reject the release?
                      </p>
                      <div className="aceept-reject-button">
                        <button
                          className="accept"
                          onClick={() => onClickAccept(release_id)}
                        >
                          Accept
                        </button>{" "}
                        <button
                          className="reject"
                          onClick={() => onClickReject(release_id)}
                        >
                          Reject
                        </button>
                      </div>
                    </>
                  ) : status === "approved" ? (
                    <p className="status-approved">Approved</p>
                  ) : status === "rejected" ? (
                    <p className="status-rejected">Rejected</p>
                  ) : status === "takendown" ? (
                    <p className="status-rejected">Takendown</p>
                  ) : null}
                  <div style={{ marginTop: 20 }}>
                    <p className="user-details-in-review">
                      Name: <span>{name} </span>
                    </p>
                    <p className="user-details-in-review">
                      Email: <span>{email}</span>
                    </p>
                  </div>
                </div>
              </Grid>

              {/* first section */}
              <Grid item xs={12} sm={12} md={4}>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>Release Type:</label>
                    </Grid>

                    <Grid item xs={7}>
                      <p style={{ textTransform: "capitalize" }}>
                        {release_type}
                      </p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>Label:</label>
                    </Grid>

                    <Grid item xs={7}>
                      <p>{lable_name}</p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>Primary Artist 1:</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>{primary_artist1}</p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2}>
                  <Grid container className="item-container apple-id">
                    <Grid item xs={5}>
                      <label>Apple Id:</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>{apple_id1}</p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2}>
                  <Grid container className="item-container apple-id">
                    <Grid item xs={5}>
                      <label>Spotify Id:</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>{spotify_id1}</p>
                    </Grid>
                  </Grid>
                </Box>
                {primary_artist2 ? (
                  <>
                    <Box pl={2} mt={2}>
                      <Grid container className="item-container">
                        <Grid item xs={5}>
                          <label>Primary Artist 2:</label>
                        </Grid>
                        <Grid item xs={7}>
                          <p>{primary_artist2}</p>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box pl={2}>
                      <Grid container className="item-container apple-id">
                        <Grid item xs={5}>
                          <label>Apple Id:</label>
                        </Grid>
                        <Grid item xs={7}>
                          <p>{apple_id2}</p>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box pl={2}>
                      <Grid container className="item-container apple-id">
                        <Grid item xs={5}>
                          <label>Spotify Id:</label>
                        </Grid>
                        <Grid item xs={7}>
                          <p>{spotify_id2}</p>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                ) : null}
                {primary_artist3 ? (
                  <>
                    <Box pl={2} mt={2}>
                      <Grid container className="item-container">
                        <Grid item xs={5}>
                          <label>Primary Artist 3:</label>
                        </Grid>
                        <Grid item xs={7}>
                          <p>{primary_artist3}</p>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box pl={2}>
                      <Grid container className="item-container apple-id">
                        <Grid item xs={5}>
                          <label>Apple Id:</label>
                        </Grid>
                        <Grid item xs={7}>
                          <p>{apple_id3}</p>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box pl={2}>
                      <Grid container className="item-container apple-id">
                        <Grid item xs={5}>
                          <label>Spotify Id:</label>
                        </Grid>
                        <Grid item xs={7}>
                          <p>{spotify_id3}</p>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                ) : null}
                {featuring1 ? (
                  <>
                    <Box pl={2} mt={2}>
                      <Grid container className="item-container">
                        <Grid item xs={5}>
                          <label>featuring Artist 1:</label>
                        </Grid>
                        <Grid item xs={7}>
                          <p>{featuring1}</p>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box pl={2}>
                      <Grid container className="item-container apple-id">
                        <Grid item xs={5}>
                          <label>Apple Id:</label>
                        </Grid>
                        <Grid item xs={7}>
                          <p>{apple_id1f}</p>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box pl={2}>
                      <Grid container className="item-container apple-id">
                        <Grid item xs={5}>
                          <label>Spotify Id:</label>
                        </Grid>
                        <Grid item xs={7}>
                          <p>{spotify_id1f}</p>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                ) : null}
                {featuring2 ? (
                  <>
                    <Box pl={2} mt={2}>
                      <Grid container className="item-container">
                        <Grid item xs={5}>
                          <label>featuring Artist 2:</label>
                        </Grid>
                        <Grid item xs={7}>
                          <p>{featuring2}</p>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box pl={2}>
                      <Grid container className="item-container apple-id">
                        <Grid item xs={5}>
                          <label>Apple Id:</label>
                        </Grid>
                        <Grid item xs={7}>
                          <p>{apple_id2f}</p>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box pl={2}>
                      <Grid container className="item-container apple-id">
                        <Grid item xs={5}>
                          <label>Spotify Id:</label>
                        </Grid>
                        <Grid item xs={7}>
                          <p>{spotify_id2f}</p>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                ) : null}
                {featuring3 ? (
                  <>
                    {" "}
                    <Box pl={2} mt={2}>
                      <Grid container className="item-container">
                        <Grid item xs={5}>
                          <label>featuring Artist 3:</label>
                        </Grid>
                        <Grid item xs={7}>
                          <p>{featuring3}</p>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box pl={2}>
                      <Grid container className="item-container apple-id">
                        <Grid item xs={5}>
                          <label>Apple Id:</label>
                        </Grid>
                        <Grid item xs={7}>
                          <p>{apple_id3f}</p>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box pl={2}>
                      <Grid container className="item-container apple-id">
                        <Grid item xs={5}>
                          <label>Spotify Id:</label>
                        </Grid>
                        <Grid item xs={7}>
                          <p>{spotify_id3f}</p>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                ) : null}
              </Grid>
              {/* second section */}
              <Grid item xs={12} sm={6} md={4}>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>Release Title:</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>{release_title}</p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>Genre:</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>{genre}</p>
                    </Grid>
                  </Grid>
                </Box>

                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>Explicit Version:</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>
                        {Array.isArray(audios) && audios.length
                          ? audios[0].explicit_version
                          : "-" || "-"}
                      </p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>Number of Tracks:</label>
                    </Grid>

                    <Grid item xs={7}>
                      <p>{number_of_tracks}</p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>Release Date:</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>{moment(Number(release_date)).format("DD/MM/yyyy")}</p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>CRBT:</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>{crbt}</p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>Video Platforms:</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>{video_platforms}</p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>Price Tier:</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>
                        {Array.isArray(audios) && audios.length
                          ? audios[0].price_tier
                          : "-" || "-"}
                      </p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>C line:</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>{c_line}</p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>P Line:</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>{p_line}</p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>Sub Genre:</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>{sub_genre}</p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>UPC/EAN:</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>{upc_ean}</p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>Status:</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>
                        {status === "in_review"
                          ? "In-Review"
                          : status === "approved"
                          ? "Approved"
                          : status === "rejected"
                          ? "Rejected"
                          : "Processed"}
                      </p>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Box pt={2} pb={2}>
          <h3>Tracks</h3>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table className="universal-table">
            <TableHead>
              <TableRow
                style={{
                  background:
                    "linear-gradient(90deg, rgba(19,153,121,1) 0%, rgba(18,180,161,1) 45%, rgba(5,150,179,1) 100%)",
                }}
              >
                <TableCell className="color-white">No.</TableCell>
                <TableCell className="color-white">Title</TableCell>
                <TableCell className="color-white">Artist</TableCell>
                <TableCell className="color-white">Genre</TableCell>
                <TableCell className="color-white">Product Number</TableCell>
                <TableCell className="color-white">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {audios && audios.length
                ? audios.map((item, key) => {
                    return (
                      <TableRow
                        key={key}
                        onClick={() => onOpenModal(item)}
                        hover
                      >
                        <TableCell>{key + 1}</TableCell>
                        <TableCell>{item.title}</TableCell>
                        <TableCell>{item.primary_artist1}</TableCell>
                        <TableCell>{item.genre}</TableCell>
                        <TableCell>{`FM-AU-${item.audio_id}`}</TableCell>
                        <TableCell>
                          {item.status === 0
                            ? "In-Review"
                            : item.status === 1
                            ? "Approved"
                            : item.status === 2
                            ? "Rejected"
                            : item.status === 3
                            ? "Takendown"
                            : ""}
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <CustomShowModal
        open={openSong}
        handleClose={() => setOpenSong(false)}
        maxWidth="md"
        title="Song Details"
      >
        <ViewSong selectedSong={selectedSong} />
      </CustomShowModal>

      <ConfirmModal
        open={openConfirm}
        message={`Are you sure you want to accept this release?`}
        title={`Confirmation`}
        onSubmitConfirm={onConfirmAcceptModal}
        handleClose={() => setOpenConfirm(false)}
      />

      <CustomAddModal
        open={openReject}
        saveLabel="Send"
        title="Add Reason"
        handleClose={() => setOpenReject(false)}
        onSubmitConfirm={onSubmitReject}
      >
        <Grid container style={{ height: "330px", display: "block" }}>
          <Grid item xs={12} style={{ marginBottom: "20px", height: "40px" }}>
            <SelectCMP
              options={rejectOptions}
              // value={selectedTicketType}
              placeholder="Select Reason"
              onChange={(e) => setReasonText(e.value)}
            />
          </Grid>

          <Grid item xs={12} style={{ height: "calc(100% - 60px)" }}>
            <textarea
              className="text-area-input"
              placeholder="Please add a reason"
              value={reasonText}
              onKeyPress={keyPressedReason}
              onChange={(e) => setReasonText(e.target.value)}
            />
          </Grid>
        </Grid>
      </CustomAddModal>

      <CustomAddModal
        open={showSongModal}
        disableOutClick
        disableSave
        saveLabel="Skip"
        title="Reject a song?"
        handleClose={() => setShowSongModal(false)}
        onSubmitConfirm={() => setShowSongModal(false)}
      >
        <Grid container>
          <Grid item xs={12}>
            <h5>Note: Please skip if you don't want to reject a song.</h5>
            <Box mt={2}>
              <Table className="universal-table">
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {audios && audios.length
                    ? audios.map((item, key) => {
                        return (
                          <TableRow key={key} hover>
                            <TableCell>{key + 1}</TableCell>
                            <TableCell>{item.title}</TableCell>
                            <TableCell>
                              {item.status === 0 ? (
                                <button
                                  className="reject-single-song"
                                  onClick={() => onRejectASong(item.audio_id)}
                                >
                                  Reject
                                </button>
                              ) : (
                                "Rejected"
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </Box>
          </Grid>
        </Grid>
      </CustomAddModal>
    </Grid>
  );
}

export default ViewReleases;
