import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, IconButton, Zoom } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Zoom ref={ref} {...props} />;
});
export default function CustomShowModal(props) {

    const { open, handleClose, maxWidth = "sm", title = 'Stores' } = props;

    return (
        <div>
            <Dialog
                open={open}
                // onClose={handleClose}
                scroll='paper'
                fullWidth
                TransitionComponent={Transition}
                maxWidth={maxWidth}
                aria-labelledby="customized-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <h3>{title}</h3>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>

                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    {props.children}
                </DialogContent>
            </Dialog>
        </div>
    );
}
