import React, { useEffect, useState, useCallback } from 'react'
import { Grid, Box, Paper, Table, TableBody, TableCell, TableRow, TableHead, TableContainer, withStyles, TextField } from "@material-ui/core"
import * as actions from "./actions"
import moment from "moment"
import { Link, useHistory } from "react-router-dom"
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SelectCmp from "react-select"
import { releaseFilters } from '../../constants'
import Pagination from '@material-ui/lab/Pagination';
import { useParams } from "react-router-dom"
import _ from "lodash";

const CssTextField = withStyles({
    root: {
        // background: "transparent",
        fontFamily: "'DM Sans', sans-serif",
        color: "#fff",
        width: "100%",
        '& label': {
            fontFamily: "'DM Sans', sans-serif",
            // color: '#10baa8',
            color: "white"
        },
        '& label.Mui-focused': {
            // color: '#10baa8',
            color: "white"
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'red',
        },
        '& .MuiOutlinedInput-root': {
            // '& fieldset': {
            // borderColor: 'white',
            // },
            // '&:hover fieldset': {
            // borderColor: 'white',
            // },
            '&.Mui-focused fieldset': {
                borderColor: '#10baa8',
                borderWidth: "1px"
            },
        },
    },
})(TextField);

export default function NewReleases(props) {

    const history = useHistory();
    const params = useParams();
    const [releases, setReleases] = useState([])
    const [filters, setFilters] = useState({
        sort: 'in_review',
        limit: 50,
        offset: 0,
        page: 1
    })
    const [count, setCount] = useState(0)
    const [search, setSearch] = useState('')
    const delaySearch = useCallback(_.debounce(filters => getSearchReleases(filters), 500), []);

    useEffect(() => {
        onGetNewReleases(filters)
    }, [filters])

    const onPageChange = (event, page) => {
        const offset = (page - 1) * filters.limit
        setFilters({ ...filters, page, offset })
    }

    const onGetNewReleases = (filters) => {
        props.setLoader(true)
        actions.getNewReleasesListAdmin(filters).then(res => {
            props.setLoader(false)
            setReleases(res.body)
            setCount(res.total)
        }).catch(err => {
            props.setLoader(false)
        })
    }

    const getSearchReleases = (filters) => {
        props.setLoader(true)
        actions.searchInReleases({ ...filters, type: 'in_review' }).then(res => {
            props.setLoader(false)
            setReleases(res.body)
        }).catch(err => {
            props.setLoader(false)
        })
    }

    const searchReleases = (value) => {
        setSearch(value)
        if (!value) {
            onGetNewReleases(filters)
        } else {
            delaySearch({ search: value })
        }
    }

    const onClickViewPage = (id) => {
        history.push(`/view_releases/${id}`)
    }

    // const onChangeSelect = (e) => {
    //     setSelectedFilter(e)
    //     setFilters({ ...filters, sort: e.value })
    // }

    let totalPages = Math.ceil(Number(count) / 50)
    if (search) totalPages = 1
    return (
        <Grid container>
            <Grid item xs={12}>
                <Box mb={2} display="flex" justifyContent="space-between">
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <h2 className="">Review Release</h2>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {/* <div className="release-filter-div">
                                {
                                    selectedFilter ? selectedFilter.value === 0 ? <AccessTimeIcon className="svg" /> : selectedFilter.value === 1 ?
                                        <CheckCircleIcon className="svg" style={{ color: "green" }} /> : selectedFilter.value === 2 ?
                                            <CancelIcon className="svg" style={{ color: "red" }} /> : null : null
                                }
                                <SelectCmp
                                    options={releaseFilters}
                                    value={selectedFilter}
                                    onChange={onChangeSelect}
                                />
                            </div> */}
                            <div>
                                <h2 style={{ float: "right" }}>Total: {count}</h2>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Box mt={2}>
                                <CssTextField
                                    type="text"
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => searchReleases(e.target.value)}
                                    value={search}
                                    placeholder="Search..."
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow style={{ background: "linear-gradient(90deg, rgba(19,153,121,1) 0%, rgba(18,180,161,1) 45%, rgba(5,150,179,1) 100%)" }}>
                                    <TableCell className="color-white">No.</TableCell>
                                    <TableCell className="color-white" >Title</TableCell>
                                    <TableCell className="color-white" >User Name</TableCell>
                                    <TableCell className="color-white" >Email</TableCell>
                                    <TableCell className="color-white" >Label</TableCell>
                                    <TableCell className="color-white" ># of tracks</TableCell>
                                    <TableCell className="color-white" >Relase Date</TableCell>
                                    <TableCell className="color-white" >Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    releases.length ? releases.map((item, key) => {
                                        return <TableRow key={key}>
                                            <TableCell>{((filters.page - 1) * filters.limit) + (key + 1)}</TableCell>
                                            <TableCell><Link to={`/view_releases/${item.release_id}`} className="link-color-black">{item.release_title || '-'}</Link></TableCell>
                                            <TableCell>{item.name || '-'}</TableCell>
                                            <TableCell>{item.email || '-'}</TableCell>
                                            <TableCell>{item.lable_name || '-'}</TableCell>
                                            <TableCell>{item.number_of_tracks || '-'}</TableCell>
                                            <TableCell>{moment(Number(item.release_date)).format('DD/MM/yyyy')}</TableCell>
                                            <TableCell>{item.status === 'in_review' ?
                                                <AccessTimeIcon /> : item.status === 'approved' ?
                                                    <CheckCircleIcon style={{ color: "green" }} /> : item.status === 'rejected' ?
                                                        <CancelIcon style={{ color: "red" }} /> : null}</TableCell>
                                        </TableRow>
                                    }) : <TableRow>
                                        <TableCell colSpan="8" align="center">No Release Found</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                        <Pagination count={totalPages} showFirstButton showLastButton onChange={onPageChange} page={filters.page || 0} />
                    </TableContainer>
                </Box>
            </Grid>
        </Grid >
    )
}
