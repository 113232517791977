import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Zoom } from '@material-ui/core';

export default function CustomAddModal(props) {
    const { handleClose, title, onSubmitConfirm, open, saveLabel = 'Add', maxWidth = "sm", disableOutClick = false, disableSave = false } = props;
    return (
        <div>
            <Dialog
                open={open}
                fullWidth
                maxWidth={maxWidth}
                TransitionComponent={Zoom}
                onClose={disableOutClick ? () => { } : handleClose}
            >
                <DialogTitle id="alert-dialog-title" className="font-family">{title}</DialogTitle>
                <DialogContent>
                    {props.children}
                </DialogContent>
                <DialogActions>
                    {disableSave ? null : <Button onClick={onSubmitConfirm} color="primary" variant="outlined" className="font-family">
                        {saveLabel}
                    </Button>}
                    <Button onClick={handleClose} color="secondary" variant="outlined" className="font-family" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}