import React from 'react';
import { Grid, Button } from "@material-ui/core";
import * as actions from "./actions";
import { toastSuccess, toastError } from "../../utils";
import { API_URL } from '../../constants';

function ViewSong(props) {
    const { selectedSong } = props;
    const {
        audio_id = '',
        release_id = '',
        video_isrc = '',
        audio_track = '',
        track_version = '',
        instrumental = '',
        title = '',
        version_subtitle = '',
        primary_artist1 = '',
        apple_id1 = '',
        spotify_id1 = '',
        primary_artist2 = '',
        apple_id2 = '',
        spotify_id2 = '',
        primary_artist3 = '',
        apple_id3 = '',
        spotify_id3 = '',
        featuring1 = '',
        apple_id1f = '',
        spotify_id1f = '',
        featuring2 = '',
        apple_id2f = '',
        spotify_id2f = '',
        featuring3 = '',
        apple_id3f = '',
        spotify_id3f = '',
        author1 = '',
        author2 = '',
        author3 = '',
        composer1 = '',
        composer2 = '',
        composer3 = '',
        producer = '',
        p_line = '',
        production_year = '',
        publisher = '',
        isrc_yes_no = '',
        isrc = '',
        genre = '',
        subgenre = '',
        price_tier = '',
        explicit_version = '',
        track_title_language = '',
        lyrics_language = '',
        lyrics = '',
        caller_tune = '',
        destribute_music = '',
        download_video_link = '',
    } = selectedSong;

    const onDelete = (item) => {
        actions
          .deleteFileFromServer({ fileLink: item })
          .then((res) => {
            toastSuccess("Audio deleted successfully.");
          })
          .catch((err) => {
            toastError(err || "Something went wrong");
          });
      }

    return (
        <Grid container>
            <Grid item xs={12}>
                <div style={{ position: "relative" }}>
                    <audio controls className="audio-media-player">
                        <source src={`${API_URL}/${audio_track}`} type="audio/ogg" />
                    </audio>
                    <a style={{ marginLeft: 18, alignSelf: "center", position: "absolute", top: "48%" }} href={`${API_URL}/${audio_track}`} download={`${audio_track}`} target="_blank" rel="noreferrer">Download (Right click, Save link as)</a>
                    {/* <a style={{ marginLeft: 18, alignSelf: "center", position: "absolute", top: "48%" }} onClick={() => window.open(`${API_URL}/${audio_track}`)} target="_blank" download={`${API_URL}/${audio_track}`}>Download</a> */}
                </div>
            </Grid>
            <Grid item xs={12}>
                <Grid container className="container-song-view">
                    <Grid item xs={12} sm={6}>
                        <p><label>Track Version</label><span>{track_version}</span></p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p><label>Instrumental</label><span>{instrumental}</span></p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p><label>Title</label><span>{title}</span></p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p><label>Version Subtitle</label><span>{version_subtitle}</span></p>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <p style={{ marginBottom: "0px" }}><label>Primary Artist 1:</label><span>{primary_artist1}</span></p>
                        <p className="dim-this-one"><label>Apple Id:</label><span>{apple_id1}</span></p>
                        <p className="dim-this-one" style={{ marginBottom: "10px" }}><label>Spotify Id:</label><span>{spotify_id1}</span></p>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <p style={{ marginBottom: "0px" }}><label>Primary Artist 2:</label><span>{primary_artist2}</span></p>
                        <p className="dim-this-one"><label>Apple Id:</label><span>{apple_id2}</span></p>
                        <p className="dim-this-one" style={{ marginBottom: "10px" }}><label>Spotify Id:</label><span>{spotify_id2}</span></p>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <p style={{ marginBottom: "0px" }}><label>Primary Artist 3:</label><span>{primary_artist3}</span></p>
                        <p className="dim-this-one"><label>Apple Id:</label><span>{apple_id3}</span></p>
                        <p className="dim-this-one" style={{ marginBottom: "10px" }}><label>Spotify Id:</label><span>{spotify_id3}</span></p>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <p style={{ marginBottom: "0px" }}><label>Featuring Artist 1:</label><span>{featuring1}</span></p>
                        <p className="dim-this-one"><label>Apple Id:</label><span>{apple_id1f}</span></p>
                        <p className="dim-this-one" style={{ marginBottom: "10px" }}><label>Spotify Id:</label><span>{spotify_id1f}</span></p>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <p style={{ marginBottom: "0px" }}><label>Featuring Artist 2:</label><span>{featuring2}</span></p>
                        <p className="dim-this-one"><label>Apple Id:</label><span>{apple_id2f}</span></p>
                        <p className="dim-this-one" style={{ marginBottom: "10px" }}><label>Spotify Id:</label><span>{spotify_id2f}</span></p>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <p style={{ marginBottom: "0px" }}><label>Featuring Artist 3:</label><span>{featuring3}</span></p>
                        <p className="dim-this-one"><label>Apple Id:</label><span>{apple_id3f}</span></p>
                        <p className="dim-this-one" style={{ marginBottom: "10px" }}><label>Spotify Id:</label><span>{spotify_id3f}</span></p>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <p><label>Author 1</label><span>{author1}</span></p>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <p><label>Author 2</label><span>{author2}</span></p>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <p><label>Author 3</label><span>{author3}</span></p>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <p><label>Composer 1</label><span>{composer1}</span></p>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <p><label>Composer 2</label><span>{composer2}</span></p>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <p><label>Composer 3</label><span>{composer3}</span></p>
                    </Grid>



                    <Grid item xs={12} sm={6}>
                        <p><label>Producer</label><span>{producer}</span></p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p><label>P Line</label><span>{p_line}</span></p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p><label>Production Year</label><span>{production_year}</span></p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p><label>Publisher</label><span>{publisher}</span></p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p><label>ISRC</label><span>{isrc_yes_no}</span></p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p><label>Audio ISRC</label><span>{isrc}</span></p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p><label>Genre</label><span>{genre}</span></p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p><label>Subgenre</label><span>{subgenre}</span></p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p><label>Price Tier</label><span>{price_tier}</span></p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p><label>Explicit Version</label><span>{explicit_version}</span></p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p><label>Track Title Language</label><span>{track_title_language}</span></p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p><label>Lyrics Language</label><span>{lyrics_language}</span></p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p><label>Lyrics</label><span>{lyrics}</span></p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p><label>Caller Tune</label><span>{caller_tune}</span></p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p><label>Destribute Music</label><span>{destribute_music}</span></p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p><label>Video ISRC</label><span>{video_isrc}</span></p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p><label>Download Video Link</label><span><a href={download_video_link}>{download_video_link}</a></span></p>
                    </Grid>
                </Grid>
                <Button
              onClick={() => onDelete(audio_track)}
              color="secondary"
              variant="contained"
              style={{ textTransform: "capitalize", float: "right", height: '35px', marginLeft: '5px' }}
            >
              Delete
            </Button>
            </Grid>

        </Grid>
    )
}

export default ViewSong