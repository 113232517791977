import React, { useEffect, useState } from 'react'
import { toastError, toastSuccess } from '../../utils'
import { Grid, Box, Button, Paper, TextField, InputLabel } from "@material-ui/core"
import * as actions from "./actions"
import CustomAddModal from '../Common/CustomAddModal';


export default function News(props) {

    const [news, setNews] = useState([])
    const [addNewsModel, setAddNewsModel] = useState(false);
    const [addNews, setAddNews] = useState({
        news_heading: '',
        news_link: ''
    })


    useEffect(() => {
        onGetNews();
    }, [])

    const onGetNews = () => {
        props.setLoader(true)
        actions.getNews().then(res => {
            props.setLoader(false)
            setNews(res.body)
        }).catch(err => {
            props.setLoader(false)
            toastError(err.message)
        })
    }

    const onClickAddNews = () => {
        setAddNewsModel(true)
    }

    const onSubmitNewsLink = () => {
        if (!addNews.news_heading && !addNews.news_link) {
            return toastError('All fields are required.')
        }
        props.setLoader(true)
        actions.addNews(addNews).then(res => {
            props.setLoader(false)
            onGetNews();
            setAddNewsModel(false)
            toastSuccess(res.message)
        }).catch(err => {
            props.setLoader(false)
            toastError(err.message)
        })
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Button
                    variant="outlined" onClick={() => onClickAddNews()}>
                    + Add news
                </Button>
            </Grid>

            <Grid item xs={12} sm={12} md={10} lg={8} style={{ margin: "auto" }}>
                {news.length ? news.map((item, key) => {
                    return <Box p={1} key={key}>
                        <Paper>
                            <Box pl={2} pt={1} pb={1}>
                                <div>
                                    <h3 style={{ marginBottom: 10 }}>News {key + 1}</h3>
                                    <p>Title: {item.news_heading}</p>
                                    <p>Link: <a href={item.news_link} target="_blank" rel="noreferrer">{item.news_link}</a></p>
                                </div>
                            </Box>
                        </Paper>
                    </Box>
                }) : null}
            </Grid>

            <CustomAddModal
                open={addNewsModel}
                title="Add News"
                onSubmitConfirm={onSubmitNewsLink}
                handleClose={() => setAddNewsModel(false)}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <InputLabel classes={{ root: 'label-tag' }}>Title</InputLabel>
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder="Title"
                            value={addNews.news_heading}
                            onChange={e => setAddNews({ ...addNews, news_heading: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel classes={{ root: 'label-tag' }}>Link</InputLabel>
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder="Link"
                            value={addNews.news_link}
                            onChange={e => setAddNews({ ...addNews, news_link: e.target.value })}
                        />
                    </Grid>
                </Grid>
            </CustomAddModal>

        </Grid >
    )
}