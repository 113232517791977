import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TextField,
  withStyles,
  Tooltip,
  Box,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import * as actions from "../Releases/actions";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CancelIcon from "@material-ui/icons/Cancel";
import _ from "lodash";
import AddBoxOutlined from "@material-ui/icons/AddBoxOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Instagram from "@material-ui/icons/Instagram";
import Facebook from "@material-ui/icons/Facebook";
import { toastError, toastSuccess } from "../../utils";
import CustomShowModal from "../Common/customShowModal";

const CssTextField = withStyles({
  root: {
    // background: "transparent",
    fontFamily: "'DM Sans', sans-serif",
    color: "#fff",
    width: "100%",
    "& label": {
      fontFamily: "'DM Sans', sans-serif",
      // color: '#10baa8',
      color: "white",
    },
    "& label.Mui-focused": {
      // color: '#10baa8',
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "red",
    },
    "& .MuiOutlinedInput-root": {
      // '& fieldset': {
      // borderColor: 'white',
      // },
      // '&:hover fieldset': {
      // borderColor: 'white',
      // },
      "&.Mui-focused fieldset": {
        borderColor: "#10baa8",
        borderWidth: "1px",
      },
    },
  },
})(TextField);

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export default function Linking(props) {
  const [PLinkNew, setPLinkNew] = useState([]);
  const [PLinkOld, setPLinkOld] = useState([]);
  const [actionsModal, setActionsModal] = useState(false);
  const [actionId, setActionId] = useState();
  const [count, setCount] = useState(0);

  const [filters, setFilters] = useState({
    limit: 50,
    offset: 0,
    page: 1,
    search: "",
  });

  const delaySearch = useCallback(
    _.debounce((filters) => onGetPlinkOld(filters), 500),
    []
  );

  useEffect(() => {
    onGetPlinkOld(filters);
    setTimeout(() => {
      props.setLoader(false);
    }, 5000);
  }, []);

  const onGetPlinkOld = (filters) => {
    props.setLoader(true);
    actions
      .getProfileLinkingOldAdmin({ ...filters })
      .then((res) => {
        props.setLoader(false);
        setPLinkOld(res.body);
        setCount(res.total);
      })
      .catch((err) => {
        props.setLoader(false);
        toastError(err.message);
      });
  };

  const onPageChange = (event, page) => {
    const offset = (page - 1) * filters.limit;
    const newFilters = { ...filters, page, offset };
    setFilters(newFilters);
    onGetPlinkOld(newFilters);
  };

  const onChangeSearch = (e) => {
    const newFilters = { ...filters, search: e.target.value };
    setFilters(newFilters);
    delaySearch(newFilters);
  };

  const onGetPLinkNew = () => {
    props.setLoader(true);
    actions
      .getProfileLinkingRequestAdmin()
      .then((res) => {
        setPLinkNew(res.body);
        props.setLoader(false);
        // setCount(res.total)
      })
      .catch((err) => {
        props.setLoader(false);
      });
  };

  useEffect(() => {
    onGetPLinkNew();
  }, []);

  let totalPages = Math.ceil(Number(count) / filters.limit);

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const handleActionOnClick = (item) => {
    setActionsModal(true);
    setActionId(item);
  };

  const onConfirmAcceptModal = (status) => {
    props.setLoader(true);
    actions
      .updateProfileLinkingstatusAdmin({ status: status, plink_id: actionId})
      .then((res) => {
        setActionsModal(false);
        props.setLoader(false);
        onGetPLinkNew();
        onGetPlinkOld(filters);
      })
      .catch((err) => {
        props.setLoader(false);
      });
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <p style={{ fontWeight: 500 }}>Review Required</p>

          <Box mt={2}>
            <CssTextField
              type="text"
              variant="outlined"
              size="small"
              onChange={onChangeSearch}
              value={filters.search}
              placeholder="Search..."
            />
          </Box>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
          <TableContainer component={Paper}>
            <Table className="universal-table">
              <TableHead>
                <TableRow
                  style={{
                    background:
                      "linear-gradient(90deg, rgba(19,153,121,1) 0%, rgba(18,180,161,1) 45%, rgba(5,150,179,1) 100%)",
                  }}
                >
                  <TableCell className="color-white">PId</TableCell>
                  <TableCell className="color-white">Name</TableCell>
                  <TableCell className="color-white">Email</TableCell>
                  <TableCell className="color-white">R-Id</TableCell>
                  <TableCell className="color-white">Release</TableCell>
                  <TableCell className="color-white">Audio</TableCell>
                  <TableCell className="color-white">Artist</TableCell>
                  <TableCell className="color-white">FB</TableCell>
                  <TableCell className="color-white">IG</TableCell>
                  <TableCell className="color-white">Date</TableCell>
                  <TableCell className="color-white">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {PLinkNew.length ? (
                  PLinkNew.map((item, key) => {
                    return (
                      <TableRow key={key}>
                        <TableCell>{item.plink_id}</TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.email}</TableCell>
                        <TableCell>{item.release_id}</TableCell>
                        <TableCell>{item.release_title}</TableCell>
                        <TableCell>{item.audio_title}</TableCell>
                        <TableCell>{item.artist}</TableCell>
                        <TableCell
                          style={{ cursor: "pointer" }}
                          onClick={() => openInNewTab(item.facebook_link)}
                        >
                          <Facebook />
                        </TableCell>
                        <TableCell
                          style={{ cursor: "pointer" }}
                          onClick={() => openInNewTab(item.instagram_link)}
                        >
                          <Instagram />
                        </TableCell>
                        <TableCell>{item.date}</TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          <AddBoxOutlined
                            onClick={() => handleActionOnClick(item.plink_id)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan="11" align="center">
                      No Requests Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <h2
            style={{
              fontWeight: 500,
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            Profile Linking History
          </h2>
          <TableContainer component={Paper}>
            <Table className="universal-table">
              <TableHead>
                <TableRow
                  style={{
                    background:
                      "linear-gradient(90deg, rgba(19,153,121,1) 0%, rgba(18,180,161,1) 45%, rgba(5,150,179,1) 100%)",
                  }}
                >
                  <TableCell className="color-white">PId</TableCell>
                  <TableCell className="color-white">Name</TableCell>
                  <TableCell className="color-white">Email</TableCell>
                  <TableCell className="color-white">RId</TableCell>
                  <TableCell className="color-white">Release</TableCell>
                  <TableCell className="color-white">Audio</TableCell>
                  <TableCell className="color-white">Artist</TableCell>
                  <TableCell className="color-white">FB</TableCell>
                  <TableCell className="color-white">IG</TableCell>
                  <TableCell className="color-white">Date</TableCell>
                  <TableCell className="color-white">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {PLinkOld.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item.plink_id}</TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>{item.release_id}</TableCell>
                      <TableCell>{item.release_title}</TableCell>
                      <TableCell>{item.audio_title}</TableCell>
                      <TableCell>{item.artist}</TableCell>
                      <TableCell
                          style={{ cursor: "pointer" }}
                          onClick={() => openInNewTab(item.facebook_link)}
                        >
                          <Facebook />
                        </TableCell>
                        <TableCell
                          style={{ cursor: "pointer" }}
                          onClick={() => openInNewTab(item.instagram_link)}
                        >
                          <Instagram />
                        </TableCell>
                      <TableCell>{item.date}</TableCell>
                      <TableCell align="center">
                        <p onClick={(e) => e.stopPropagation()}>
                          {item.status === "rejected" ? (
                            <LightTooltip title="Rejected">
                              <CancelIcon style={{ color: "red" }} />
                            </LightTooltip>
                          ) : (
                            <LightTooltip title="Approved">
                              <CheckCircleIcon style={{ color: "green" }} />
                            </LightTooltip>
                          )}
                        </p>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Pagination
              count={totalPages}
              showFirstButton
              showLastButton
              onChange={onPageChange}
              page={filters.page || 0}
            />
          </TableContainer>
        </Grid>
      </Grid>
      <CustomShowModal
        handleClose={() => setActionsModal(false)}
        title={"What's your action ?"}
        open={actionsModal}
        maxWidth="sm"
      >
        <div className="aceept-reject-button">
          <button className="accept" onClick={() => onConfirmAcceptModal("approved")}>
            Accept
          </button>
          <button className="reject" onClick={() => onConfirmAcceptModal("rejected")}>
            Reject
          </button>
        </div>
      </CustomShowModal>
      {/* <CustomShowModal
        handleClose={() => setLinksModal(false)}
        title={"List of all the links"}
        open={linksModal}
        maxWidth="sm"
      >
        {linksList.length > 0 && (
          <ul>
            {linksList.map((value) => {
              return (
                <li>
                  <a href={value}>{value}</a>
                </li>
              );
            })}
          </ul>
        )}
        {linksList.length === 0 && (
          <h3 style={{ textAlign: "center" }}>No links added by user</h3>
        )}
      </CustomShowModal> */}
    </div>
  );
}
