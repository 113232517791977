import {
    getAllbroadcastNotificationAdminAPI,
    getNotificationsForUserAPI,
    broadcastNotificationAdminAPI,
    getAllSpecificNotificationAdminAPI,
    getSpecificNotificationByIdAdminByIdAPI,
    deleteNotificationByIdAdminAPI
} from "./api"

export function getAllbroadcastNotificationAdmin(data) {
    return new Promise((resolve, reject) => {
        getAllbroadcastNotificationAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}
export function getNotificationsForUser(data) {
    return new Promise((resolve, reject) => {
        getNotificationsForUserAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function broadcastNotificationAdmin(data) {
    return new Promise((resolve, reject) => {
        broadcastNotificationAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function getAllSpecificNotificationAdmin(data) {
    return new Promise((resolve, reject) => {
        getAllSpecificNotificationAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}
export function getSpecificNotificationByIdAdminById(data) {
    return new Promise((resolve, reject) => {
        getSpecificNotificationByIdAdminByIdAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function deleteNotificationByIdAdmin(data) {
    return new Promise((resolve, reject) => {
        deleteNotificationByIdAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

// export function addNews(data) {
//     return new Promise((resolve, reject) => {
//         addNewsAPI(data).then(res => resolve(res)).catch(err => reject(err))
//     })
// }