import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Broadcast from "./Broadcast"
import UserSpecific from "./UserSpecific"
import * as actions from "./actions"
import CustomAddModal from "../Common/CustomAddModal"
import { toastError, toastSuccess } from '../../utils';
import UserSpecificSingle from './UserSpecificSingle';
import CustomShowModal from '../Common/customShowModal';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {/* <Typography>{children}</Typography> */}
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
    },
}));

const inititalBroadcast = {
    notification: ""
}

export default function Notification(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { setLoader = () => { } } = props
    const [value, setValue] = useState(0);

    // broadcast
    const [broadcasts, setBroadcasts] = useState([])
    const [broadcastsFilters, setBroadcastsFilters] = useState({
        limit: 20,
        offset: 0,
        page: 1
    })
    const [broadcastCount, setBroadcastCount] = useState(0)
    const [broadcastOpen, setBroadcastOpen] = useState(false)
    const [broadcast, setBroadcast] = useState({ ...inititalBroadcast })

    // userspacific
    const [userSpecific, setUserSpecific] = useState([])
    const [userSpecificCount, setUserSpecificCount] = useState(0)
    const [userFilters, setUserFilters] = useState({
        limit: 20,
        offset: 0,
        page: 1
    })
    const [notificationOpen, setNotificationOpen] = useState(false)
    const [userList, setUserList] = useState([])

    useEffect(() => {
        onGetAllBroadcast()
    }, [broadcastsFilters])

    useEffect(() => {
        onGetAllUserSpecific()
    }, [userFilters])

    const onGetAllBroadcast = () => {
        setLoader(true)
        actions.getAllbroadcastNotificationAdmin(broadcastsFilters).then(res => {
            setLoader(false)
            setBroadcasts((Array.isArray(res.body) && res.body) || [])
            setBroadcastCount(res.total)
        }).catch(err => {
            setLoader(false)
        })
    }

    const onGetAllUserSpecific = () => {
        setLoader(true)
        actions.getAllSpecificNotificationAdmin(userFilters).then(res => {
            setLoader(false)
            setUserSpecific((Array.isArray(res.body) && res.body) || [])
            setUserSpecificCount(res.total)
        }).catch(err => {
            setLoader(false)
        })
    }

    const onPageChangeBroadCast = (event, page) => {
        const offset = (page - 1) * broadcastsFilters.limit
        setBroadcastsFilters({ ...broadcastsFilters, page, offset })
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const onCloseModal = () => {
        setBroadcast({ ...inititalBroadcast })
        setBroadcastOpen(false)
    }

    const onSubmitBroadcast = () => {
        if (!broadcast.notification.trim()) return
        setLoader(true)
        actions.broadcastNotificationAdmin(broadcast).then(res => {
            setLoader(false)
            toastSuccess(res.message)
            onCloseModal()
            onGetAllBroadcast()
        }).catch((err) => {
            setLoader(false)
            toastError(err.message)
        })
    }

    const onOpenNotification = (id) => {
        setLoader(true)
        actions.getSpecificNotificationByIdAdminById(id).then(res => {
            setLoader(false)
            setUserList(res.body)
            setNotificationOpen(true)
        }).catch(err => {
            toastError(err.message)
            setLoader(false)
        })
    }

    const onCloseNotification = () => {
        setNotificationOpen(false)
    }

    const onDeleteNotification = (id) => {
        setLoader(true)
        actions.deleteNotificationByIdAdmin({ id, deleteWhat: value === 0 ? 'broadcast' : 'specific' }).then(res => {
            setLoader(false)
            toastSuccess(res.message)
            if (value === 0) {
                onGetAllBroadcast()
            } else {
                onGetAllUserSpecific()
            }
        }).catch(err => {
            setLoader(false)
            toastError(err.message)
        })
    }

    //     deleteNotificationByIdAdmin
    // params 
    // id
    // deleteWhat
    // deleteWhat = 

    //  broadcast, specific

    let totalPages = Math.ceil(Number(broadcastCount) / broadcastsFilters.limit)
    // if (search) totalPages = 1
    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label={`Broadcast  ${broadcastCount ? `(${broadcastCount})` : ''}`} {...a11yProps(0)} />
                    <Tab label={`User Specific  ${userSpecificCount ? `(${userSpecificCount})` : ''}`} {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <div className="full-width-tab-panel">
                        <Broadcast
                            broadcasts={broadcasts}
                            totalPages={totalPages}
                            onPageChange={onPageChangeBroadCast}
                            filters={broadcastsFilters}
                            setBroadcastOpen={setBroadcastOpen}
                            onDeleteNotification={onDeleteNotification}
                        />
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <div className="full-width-tab-panel">
                        <UserSpecific
                            userSpecific={userSpecific}
                            totalPages={0}
                            onPageChange={() => { }}
                            filters={userFilters}
                            setNotificationOpen={onOpenNotification}
                            onDeleteNotification={onDeleteNotification}
                        />
                    </div>
                </TabPanel>
            </SwipeableViews>
            <CustomAddModal
                handleClose={onCloseModal}
                title={"Broadcast"}
                open={broadcastOpen}
                saveLabel='Send'
                onSubmitConfirm={onSubmitBroadcast}
            >
                <textarea
                    className="text-area-input"
                    placeholder="Enter Broadcast text"
                    value={broadcast.notification}
                    onChange={e => setBroadcast({ notification: e.target.value })}
                />
            </CustomAddModal>

            <CustomShowModal
                handleClose={onCloseNotification}
                title={"Sent to"}
                open={notificationOpen}
                maxWidth="md"
            >
                <UserSpecificSingle
                    userList={userList}
                />
            </CustomShowModal>

        </div>
    );
}