import React, { useEffect, useState, useCallback } from 'react'
import { Grid, Box, Paper, Table, TableBody, TableCell, TableRow, TableHead, TableContainer, withStyles, TextField } from "@material-ui/core"
import * as actions from "../Releases/actions";
import moment from "moment"
import { Link, useHistory } from "react-router-dom"
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import SelectCmp from "react-select"
import { releaseFilters } from '../../constants'
import SelectCMP from "react-select"
import Pagination from '@material-ui/lab/Pagination';
import { useParams } from "react-router-dom"
import _ from "lodash";

const CssTextField = withStyles({
    root: {
        // background: "transparent",
        fontFamily: "'DM Sans', sans-serif",
        color: "#fff",
        width: "100%",
        '& label': {
            fontFamily: "'DM Sans', sans-serif",
            // color: '#10baa8',
            color: "white"
        },
        '& label.Mui-focused': {
            // color: '#10baa8',
            color: "white"
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'red',
        },
        '& .MuiOutlinedInput-root': {
            // '& fieldset': {
            // borderColor: 'white',
            // },
            // '&:hover fieldset': {
            // borderColor: 'white',
            // },
            '&.Mui-focused fieldset': {
                borderColor: '#10baa8',
                borderWidth: "1px"
            },
        },
    },
})(TextField);

export default function VideoReleasesHistory(props) {

    const history = useHistory();
    const params = useParams();
    const [releases, setReleases] = useState([])
    const [filters, setFilters] = useState({
        sort: 'approved',
        limit: 50,
        offset: 0,
        search: '',
        page: 1
    })
    const [count, setCount] = useState(0)
    const [search, setSearch] = useState('');
    const [selectedFilter, setSelectedFilter] = useState(    {
        label: 'Approved',
        value: 'approved'
    });
    const delaySearch = useCallback(_.debounce(filters => onGetNewReleases(filters), 500), []);

    useEffect(() => {
        onGetNewReleases(filters)
    }, [filters]);

    useEffect(() => {
      if(!!search) {
          setCount(releases.length || 0)
      }
  });

    const onPageChange = (event, page) => {
        const offset = (page - 1) * filters.limit
        setFilters({ ...filters, page, offset })
    }

    const onGetNewReleases = (filters) => {
      props.setLoader(true)
      actions.getVReleasesAdmin(filters).then(res => {
          props.setLoader(false)
          setReleases(res.body)
          setCount(res.total)
      }).catch(err => {
          props.setLoader(false)
      })
  }

  const searchReleases = (value) => {
      setSearch(value)
      if (!value) {
          onGetNewReleases(filters)
      } else {
          delaySearch({ ...filters, search: value })
      }
  }

    const onClickViewPage = (id) => {
        history.push(`/view_releases/${id}`)
    }

    const onChangeSelect = (e) => {
        setSelectedFilter(e)
        setFilters({ ...filters, sort: e.value })
    }

    let totalPages = Math.ceil(Number(count) / filters.limit)
    // if (search) totalPages = 1
    return (
        <Grid container>
            <Grid item xs={12}>
                <Box mb={2} display="flex" justifyContent="space-between">
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <h2 className="">Video Releases History</h2>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {/* <div className="release-filter-div">
                                {
                                    selectedFilter ? selectedFilter.value === 0 ? <AccessTimeIcon className="svg" /> : selectedFilter.value === 1 ?
                                        <CheckCircleIcon className="svg" style={{ color: "green" }} /> : selectedFilter.value === 2 ?
                                            <CancelIcon className="svg" style={{ color: "red" }} /> : null : null
                                }
                                <SelectCmp
                                    options={releaseFilters}
                                    value={selectedFilter}
                                    onChange={onChangeSelect}
                                />
                            </div> */}
                            <div>
                                <h2 style={{ float: "right" }}>Total: {count}</h2>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={8} md={10}>
                <Box mt={2} mb={2}>
                    <CssTextField
                        type="text"
                        variant="outlined"
                        size="small"
                        onChange={(e) => searchReleases(e.target.value)}
                        value={search}
                        placeholder="Search..."
                    />
                </Box>
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
                <Box mt={2} mb={2} pl={2}>
                    <SelectCMP
                        options={releaseFilters}
                        value={selectedFilter}
                        onChange={e => onChangeSelect(e)}
                    />
                </Box>
            </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow style={{ background: "linear-gradient(90deg, rgba(19,153,121,1) 0%, rgba(18,180,161,1) 45%, rgba(5,150,179,1) 100%)" }}>
                                    <TableCell className="color-white">No.</TableCell>
                                    <TableCell className="color-white" >Release Title</TableCell>
                                    <TableCell className="color-white" >Song Title</TableCell>
                                    <TableCell className="color-white" >User Name</TableCell>
                                    <TableCell className="color-white" >Email</TableCell>
                                    <TableCell className="color-white" >Relase Date</TableCell>
                                    <TableCell className="color-white" >Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    releases.length ? releases.map((item, key) => {
                                        return <TableRow key={key}>
                                            <TableCell>{((filters.page - 1) * filters.limit) + (key + 1)}</TableCell>
                                            <TableCell><Link to={`/view_video_release/${item.video_id}`} className="link-color-black">{item.release_title || '-'}</Link></TableCell>
                                            <TableCell>{item.audio_title || '-'}</TableCell>
                                            <TableCell>{item.name || '-'}</TableCell>
                                            <TableCell>{item.email || '-'}</TableCell>
                                            <TableCell>{moment(Number(item.release_date)).format('DD/MM/yyyy')}</TableCell>
                                            <TableCell>{item.status === 'in_review' ?
                                                <AccessTimeIcon /> : item.status === 'approved' ?
                                                    <CheckCircleIcon style={{ color: "green" }} /> : item.status === 'rejected' ?
                                                        <CancelIcon style={{ color: "red" }} /> : 
                                                        <RemoveCircleOutlineIcon style={{ color: "red" }}/>
                                                        }</TableCell>
                                        </TableRow>
                                    }) : <TableRow>
                                        <TableCell colSpan="8" align="center">No Release Found</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                        <Pagination count={totalPages} showFirstButton showLastButton onChange={onPageChange} page={filters.page || 0} />
                    </TableContainer>
                </Box>
            </Grid>
        </Grid >
    )
}
