import React, { useMemo } from 'react'
import { Grid, Button, Table, TableHead, TableBody, TableCell, TableRow, TableContainer, Paper } from "@material-ui/core"
import Pagination from '@material-ui/lab/Pagination';
import moment from "moment"
import DeleteIcon from '@material-ui/icons/Delete';

export default function UserSpecific(props) {

    const {
        userSpecific = [],
        totalPages = 0,
        onPageChange = () => { },
        filters = {},
        setNotificationOpen = () => { },
        onDeleteNotification = () => { },
    } = props

    return useMemo(() => (
        <Grid container>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow style={{ background: "linear-gradient(90deg, rgba(19,153,121,1) 0%, rgba(18,180,161,1) 45%, rgba(5,150,179,1) 100%)" }}>
                                <TableCell className="color-white" >S.no</TableCell>
                                <TableCell className="color-white">Notification</TableCell>
                                <TableCell className="color-white">Sent On</TableCell>
                                <TableCell className="color-white">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                userSpecific.length ? userSpecific.map((item, index) => {
                                    return <TableRow key={index} onClick={() => setNotificationOpen(item.notification_id)}>
                                        <TableCell>{((filters.page - 1) * filters.limit) + (index + 1)}</TableCell>
                                        <TableCell>{item.notification}</TableCell>
                                        <TableCell>{moment(item.created_at).format('DD/MM/yyyy HH:mm')}</TableCell>
                                        <TableCell><DeleteIcon style={{ color: "red" }} onClick={(e) => { e.stopPropagation(); onDeleteNotification(item.notification_id) }} /></TableCell>
                                    </TableRow>
                                }) : null
                            }
                        </TableBody>
                    </Table>
                    <Pagination count={totalPages} showFirstButton showLastButton onChange={onPageChange} page={filters.page || 0} />
                </TableContainer>
            </Grid>
        </Grid>
    ), [userSpecific, totalPages, filters])
}
