import React, { useEffect, useState } from 'react'
import * as actions from "../Common/actions"
import { useParams } from "react-router-dom"
import { Grid, Box, Paper, Button, TextField } from "@material-ui/core"
import CustomAddModal from "../Common/CustomAddModal"
import { useHistory } from "react-router-dom"
import { toastSuccess } from '../../utils'
import moment from "moment"

function ViewUserDetails(props) {

    const params = useParams();
    const history = useHistory();
    const [userDetails, setUserDetails] = useState({})
    const [bankDetails, setBankDetails] = useState({})
    const [openRevenue, setOpenRevenue] = useState(false)
    const [addRevenue, setAddRevenue] = useState('')

    useEffect(() => {
        getUserDetailsById()
    }, [])

    const getUserDetailsById = () => {
        props.setLoader(true)
        actions.getUserDetailsByIdForAdmin(params.id).then(res => {
            props.setLoader(false)
            setUserDetails(res.userDetails)
            setBankDetails(res.bankDetails)
            setAddRevenue(res.userDetails.revenue)
        }).catch(err => {
            props.setLoader(false)
            history.push('/user_details')
        })
    }

    const onUpdateRevenue = () => {
        props.setLoader(true)
        actions.addRevenueAdmin({ user_id: params.id, revenue: addRevenue }).then((res) => {
            props.setLoader(false)
            setOpenRevenue(false)
            toastSuccess(res.message)
            getUserDetailsById()
        }).catch(err => {
            props.setLoader(false)

        })
    }

    const {
        name = '',
        email = '',
        phone = '',
        address = '',
        created_at = '',
        ip = '',
        revenue = '',
        revoked = '',
        total_releases = '',
        total_drafts = '',
        total_artist = '',
        total_reports_requested = '',
        total_tickets_created = '',
        total_open_tickets = '',

    } = userDetails


    const {
        bank_name = '',
        ifsc_code = '',
        account_number = '',
        holder_name = '',
        other_details = '',
    } = bankDetails
    return (
        <Grid container className="view-user-details-container">
            <Grid item xs={12} sm={12} md={8}>
                <Box p={1} className="box-container">
                    <h3>User Details</h3>
                    <Paper>
                        <Box p={1}>
                            <div className="details-div">
                                <p><label>Name:</label> <span>{name}</span></p>
                                <p><label>Email:</label> <span>{email}</span></p>
                                <p><label>Address:</label> <span>{address}</span></p>
                                <p><label>Phone:</label> <span>{phone}</span></p>
                                <p><label>Revenue:</label> <span>${revenue}</span></p>
                                <p><label>Signup Date:</label> <span>{moment(created_at).format('DD/MM/yyyy hh:mm:ss A')}</span></p>
                                <p><label>IP:</label> <span>{ip}</span></p>
                                <p><label>Revoked:</label> <span>{revoked === 0 ? 'No' : 'Yes'}</span></p>
                                <p><label>Total Releases:</label> <span>{total_releases}</span></p>
                                <p><label>Total Drafts:</label> <span>{total_drafts}</span></p>
                                <p><label>Total Artist:</label> <span>{total_artist}</span></p>
                                <p><label>Total Reports Requested:</label> <span>{total_reports_requested}</span></p>
                                <p><label>Total Tickets Created:</label> <span>{total_tickets_created}</span></p>
                                <p><label>Total Open Tickets:</label> <span>{total_open_tickets}</span></p>
                            </div>
                        </Box>
                    </Paper>
                </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
                <Box p={1} className="box-container">
                    <h3>Bank Details</h3>
                    <Paper>
                        <Box p={1}>
                            <div className="details-div">
                                <p><label>Bank Name:</label> <span>{bank_name}</span></p>
                                <p><label>IFSC Code:</label> <span>{ifsc_code}</span></p>
                                <p><label>Account Number:</label> <span>{account_number}</span></p>
                                <p><label>Holder Name:</label> <span>{holder_name}</span></p>
                                <p><label>Other Details:</label> <span>{other_details}</span></p>
                            </div>
                        </Box>
                    </Paper>
                </Box>
                <Box textAlign="center" mt={1}>
                    <Button className="add-revenue-button" onClick={() => setOpenRevenue(true)}>Add Revenue</Button>
                </Box>
            </Grid>

            <CustomAddModal
                open={openRevenue}
                title="Add/Update Revenue"
                handleClose={() => setOpenRevenue(false)}
                onSubmitConfirm={onUpdateRevenue}
                saveLabel='Update'
            >
                <TextField
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={addRevenue}
                    onChange={(e) => setAddRevenue(e.target.value)}
                    placeholder="Add Revenue"
                />
            </CustomAddModal>
        </Grid >
    )
}

export default ViewUserDetails
