import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Button,
} from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import * as actions from "../Releases/actions";
import { API_URL, releaseFilters } from "../../constants";
import moment from "moment";
import CustomShowModal from "../Common/customShowModal";
import * as commonActions from "../Common/actions";
import ConfirmModal from "../Common/ConfirmModal";
import CustomAddModal from "../Common/CustomAddModal";
import { toastSuccess, toastError } from "../../utils";
import SelectCMP from "react-select";

function ViewVideoReleases(props) {
  const params = useParams();
  const history = useHistory();

  const [release, setRelease] = useState({});
  const [openSong, setOpenSong] = useState(false);
  const [selectedSong, setSelectedSong] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [openReject, setOpenReject] = useState(false);
  const [reasonText, setReasonText] = useState("");
  const [showSongModal, setShowSongModal] = useState(false);
  const [rejectOptions, setRejectOptions] = useState([]);
  const [recipeFilePreview, setRecipeFilePreview] = useState(null);
  const [updatedPoster, setUpdatedPoster] = useState("");

  const onFileHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 1572864) {
        return toastError("File more than 1.5 MB is not allowed.");
      }
      props.setLoader(true);
      commonActions
        .videoUpload(file)
        .then((res) => {
          props.setLoader(false);
          setUpdatedPoster(res.body.filename);
          previewsFile(file);
          toastSuccess("Thumbnail uploaded successfully.");
        })
        .catch((err) => {
          props.setLoader(false);
          toastError(err.message || "Something went wrong");
        });
    }
  };

  const onDelete = (item) => {
    props.setLoader(true);
    actions
      .deleteVThumnailFromServer({ fileLink: item })
      .then((res) => {
        window.location.reload(false);
      })
      .catch((err) => {
        props.setLoader(false);
      });
  }

    const onSaveUpdatedArtwork = () => {
      actions.updateVThumbnailAdmin({ video_id: params.id, thumbnail: `${API_URL}/${updatedPoster}` || '' }).then(res => {
          toastSuccess(res.message || 'Updated successfully');
          setRecipeFilePreview(null);
          onGetRelease();
      }).catch(err => {
          toastError(err.message)
      })
  }

  const previewsFile = (file) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        //convert
        setRecipeFilePreview(reader.result);
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    onGetRelease();
    onGetRejectOptions();
  }, []);

  const onGetRejectOptions = () => {
    actions.rejectReasonsAdmin().then((res) => {
      setRejectOptions(
        Array.isArray(res.body)
          ? res.body.map((item) => ({ label: item.reason, value: item.reason }))
          : []
      );
    });
  };

  const onGetRelease = () => {
    props.setLoader(true);
    actions
      .getVReleaseByIdAdmin({ video_id: params.id })
      .then((res) => {
        props.setLoader(false);
        setRelease(res.body);
      })
      .catch((err) => {
        props.setLoader(false);
      });
  };

  const onConfirmAcceptModal = () => {
    props.setLoader(true);
    actions
      .changeVideoReleaseStatusAdmin({
        status: "approved",
        video_id: selectedId,
      })
      .then((res) => {
        setOpenConfirm(false);
        props.setLoader(false);
        onGetRelease();
      })
      .catch((err) => {
        props.setLoader(false);
      });
  };

  const onClickAccept = (id) => {
    setSelectedId(id);
    setOpenConfirm(true);
  };

  // const onClickReject = (id) => {
  //   setSelectedId(id);
  //   onSubmitReject()
  //   // setOpenReject(true);
  // };

  const onSubmitReject = (id) => {
    props.setLoader(true);
    actions
      .changeVideoReleaseStatusAdmin({
        video_id: id,
        status: "rejected",
        reason: reasonText,
      })
      .then((res) => {
        setOpenReject(false);
        props.setLoader(false);
        setShowSongModal(true);
        onGetRelease();
      })
      .catch(() => {
        props.setLoader(false);
      });
  };

  const onDeleteRejectedRelease = (video_id) => {
    props.setLoader(true);
    actions
      .deleteVideoReleaseAdmin({ video_id })
      .then((res) => {
        props.setLoader(false);
        toastSuccess(res.message);
        return history.push("/video_releases/history");
      })
      .catch((err) => {
        props.setLoader(false);
      });
  };

  const keyPressedReason = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      return;
    } else if (e.key === "Enter") {
      e.preventDefault();
      onSubmitReject();
    } else return;
  };

  const onTakeDownRelease = (video_id) => {
    props.setLoader(true);
    actions
      .changeVideoReleaseStatusAdmin({ video_id, status: "takendown" })
      .then((res) => {
        props.setLoader(false);
        onGetRelease();
      })
      .catch(() => {
        props.setLoader(false);
      });
  };

  return (
    <Grid container className="view-release-container">
      {release.status === "rejected" ? (
        <Grid item xs={12}>
          <Box>
            <Button
              onClick={() => onDeleteRejectedRelease(release.video_id)}
              color="secondary"
              variant="contained"
              style={{ textTransform: "capitalize", float: "right" }}
            >
              Delete Release
            </Button>
          </Box>
        </Grid>
      ) : 
      release.status === "approved" ? (
        <Grid item xs={12}>
          <Box>
            <Button
              onClick={() => onTakeDownRelease(release.video_id)}
              color="secondary"
              variant="contained"
              style={{
                textTransform: "capitalize",
                float: "right",
                marginLeft: 20,
              }}
            >
              Take down Release
            </Button>
            <Button
              onClick={() => onSubmitReject(release.video_id)}
              color="secondary"
              variant="contained"
              style={{ textTransform: "capitalize", float: "right" }}
            >
              Reject Release
            </Button>
          </Box>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Paper>
          <Box mt={4} mb={2} pt={4} pb={4}>
            <Grid container className="catalog-image">
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                style={{ display: "flex", height: "auto" }}
              >
                <div
                  style={{
                    margin: "auto",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  <div className="image-div">
                    <img src={recipeFilePreview || release.thumbnail} alt="" />
                  </div>
                  <a
                    href={recipeFilePreview || release.thumbnail}
                    download="hello"
                    target="_blank"
                    rel="noreferrer"
                    className="download-link"
                  >
                    Download
                  </a>
                  <div
                    style={{
                      marginTop: "25px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    className="aceept-reject-button"
                  >
                    {recipeFilePreview ? (
                      <button className="accept" onClick={onSaveUpdatedArtwork}>Save</button>
                    ) : (
                      <label class="custom-file-upload">
                        <input
                          type="file"
                          accept="image/x-png,image/gif,image/jpeg"
                          onChange={onFileHandler}
                        />
                        Update Poster
                      </label>
                    )}

            <Button
              onClick={() => onDelete(release.thumbnail)}
              color="secondary"
              variant="contained"
              style={{ textTransform: "capitalize", float: "right", height: '35px', marginLeft: '5px' }}
            >
              Delete
            </Button>
                  </div>
                  {release.status === "in_review" ? (
                    <>
                      <p style={{ marginTop: 45 }}>
                        Do you want to accept or reject the release?
                      </p>
                      <div className="aceept-reject-button">
                        <button
                          className="accept"
                          onClick={() => onClickAccept(release.video_id)}
                        >
                          Accept
                        </button>{" "}
                        <button
                          className="reject"
                          onClick={() => onSubmitReject(release.video_id)}
                        >
                          Reject
                        </button>
                      </div>
                    </>
                  ) : release.status === "approved" ? (
                    <p className="status-approved">Approved</p>
                  ) : release.status === "rejected" ? (
                    <p className="status-rejected">Rejected</p>
                  ) : release.status === "takendown" ? (
                    <p className="status-rejected">Takendown</p>
                  ) : null}
                </div>
              </Grid>

              {/* first section */}
              <Grid item xs={12} sm={12} md={4}>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>User Name:</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>{release.name}</p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>User Email:</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>{release.email}</p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>Release Id:</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>{release.release_id}</p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>Release Title:</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>{release.release_title}</p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>Audio Title:</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>{release.audio_title}</p>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {/* second section */}
              <Grid item xs={12} sm={6} md={4}>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>Download Link</label>
                    </Grid>

                    <Grid item xs={7}>
                      <p>
                        <a
                          href={release.download_link}
                          target="_blank"
                          download
                        >
                          Click Me
                        </a>
                      </p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>Release Date:</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>
                        {moment(Number(release.release_date)).format(
                          "DD/MM/yyyy"
                        )}
                      </p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>ISRC</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>{release.video_isrc}</p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>UPC</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>{release.video_upc}</p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>Actor(s)</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>{release.actor}</p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>Actress</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>{release.actress}</p>
                    </Grid>
                  </Grid>
                </Box>
                <Box pl={2} mt={2}>
                  <Grid container className="item-container">
                    <Grid item xs={5}>
                      <label>Status:</label>
                    </Grid>
                    <Grid item xs={7}>
                      <p>
                        {release.status === "in_review"
                          ? "In-Review"
                          : release.status === "approved"
                          ? "Approved"
                          : release.status === "rejected"
                          ? "Rejected"
                          : "Processed"}
                      </p>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>

      <ConfirmModal
        open={openConfirm}
        message={`Are you sure you want to accept this release?`}
        title={`Confirmation`}
        onSubmitConfirm={onConfirmAcceptModal}
        handleClose={() => setOpenConfirm(false)}
      />

      <CustomAddModal
        open={openReject}
        saveLabel="Send"
        title="Add Reason"
        handleClose={() => setOpenReject(false)}
        onSubmitConfirm={onSubmitReject}
      >
        <Grid container style={{ height: "330px", display: "block" }}>
          <Grid item xs={12} style={{ marginBottom: "20px", height: "40px" }}>
            <SelectCMP
              options={rejectOptions}
              // value={selectedTicketType}
              placeholder="Select Reason"
              onChange={(e) => setReasonText(e.value)}
            />
          </Grid>

          <Grid item xs={12} style={{ height: "calc(100% - 60px)" }}>
            <textarea
              className="text-area-input"
              placeholder="Please add a reason"
              value={reasonText}
              onKeyPress={keyPressedReason}
              onChange={(e) => setReasonText(e.target.value)}
            />
          </Grid>
        </Grid>
      </CustomAddModal>
    </Grid>
  );
}

export default ViewVideoReleases;
