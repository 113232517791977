import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Grid, Box, Paper, Table, TableBody, TableCell, TableRow, TableHead, TableContainer, withStyles, TextField, Button } from "@material-ui/core"
import * as actions from "../Common/actions"
import { Link, useHistory } from "react-router-dom"
import { toastError, toastSuccess } from '../../utils'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import _ from "lodash";
import CustomAddModal from '../Common/CustomAddModal';
import TableBodyUser from './TableBodyUser'

const CssTextField = withStyles({
    root: {
        // background: "transparent",
        fontFamily: "'DM Sans', sans-serif",
        color: "#fff",
        width: "100%",
        '& label': {
            fontFamily: "'DM Sans', sans-serif",
            // color: '#10baa8',
            color: "white"
        },
        '& label.Mui-focused': {
            // color: '#10baa8',
            color: "white"
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'red',
        },
        '& .MuiOutlinedInput-root': {
            // '& fieldset': {
            // borderColor: 'white',
            // },
            // '&:hover fieldset': {
            // borderColor: 'white',
            // },
            '&.Mui-focused fieldset': {
                borderColor: '#10baa8',
                borderWidth: "1px"
            },
        },
    },
})(TextField);

export default function UserDetails(props) {

    const [userDetails, setUserDetails] = useState([])
    const [userCount, setUserCount] = useState(0)
    const history = useHistory();
    const [search, setSearch] = useState('')
    const [selectedUsers, setSelectedUsers] = useState([])
    const [openNotificaton, setOpenNotificaton] = useState(false)
    const [notificationText, setNotificationText] = useState('')

    const delaySearch = useCallback(_.debounce(filters => onGetNewReleases(filters), 500), []);

    useEffect(() => {
        onGetNewReleases()
    }, [])

    const onGetNewReleases = (filters = {}) => {
        props.setLoader(true)
        actions.getUserListAdmin(filters).then(res => {
            props.setLoader(false)
            setUserDetails(res.body)
            setUserCount(res.active_users)
        }).catch(err => {
            props.setLoader(false)
        })
    }

    const onClickViewPage = (id) => {
        history.push(`/view_user_details/${id}`)
    }

    const searchReleases = (value) => {
        setSearch(value)
        delaySearch({ search: value })
    }

    const revokeUser = (e, user_id, revoked) => {
        e.stopPropagation()
        props.setLoader(true)
        actions.revokeUnrevokeUserForAdmin({ user_id, revoked }).then(res => {
            props.setLoader(false)
            toastSuccess(res.message)
            onGetNewReleases()
        }).catch(err => {
            props.setLoader(false)

        })
    }

    const userDetailsList = useMemo(() => {
        return userDetails.length ? userDetails.filter(item => item.verified === '1') : []
    }, [userDetails])

    const onClickAddUser = useCallback((item) => {
        let newSelectedUser = [...selectedUsers]
        const findItem = newSelectedUser.findIndex(user => user.user_id === item.user_id)
        if (findItem > -1) {
            newSelectedUser.splice(findItem, 1)
        } else {
            newSelectedUser.push({ user_id: item.user_id, name: item.name, email: item.email })
        }
        setSelectedUsers(newSelectedUser)
    }, [selectedUsers])

    const onSubmitNotification = () => {
        if (notificationText) {
            props.setLoader(true)
            const sendData = {
                user_id: selectedUsers.map(item => item.user_id),
                notification: notificationText
            }
            actions.sendSpecificNotificationsAdmin(sendData).then(res => {
                toastSuccess(res.message || 'Success')
                setSelectedUsers([])
                setOpenNotificaton(false)
                setNotificationText('')
                props.setLoader(false)
            }).catch(err => {
                toastError(err.message)
                props.setLoader(false)
            })
        }

    }

    const showOpenNotificationButton = useMemo(() => {
        return selectedUsers.length
    }, [selectedUsers])

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
                    <h2>User Details</h2>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {showOpenNotificationButton ? <Button style={{ marginRight: 20 }} onClick={() => setOpenNotificaton(true)} color="primary" variant="contained">Send Notification</Button> : null}
                        <h2>Total users: {userCount}</h2>
                    </div>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box mt={2} mb={2}>
                    <CssTextField
                        type="text"
                        variant="outlined"
                        size="small"
                        onChange={(e) => searchReleases(e.target.value)}
                        value={search}
                        placeholder="Search..."
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box>
                    <TableContainer component={Paper}>
                        <Table className="universal-table">
                            <TableHead>
                                <TableRow style={{ background: "linear-gradient(90deg, rgba(19,153,121,1) 0%, rgba(18,180,161,1) 45%, rgba(5,150,179,1) 100%)" }}>
                                    <TableCell className="color-white">No.</TableCell>
                                    <TableCell className="color-white" >Name</TableCell>
                                    <TableCell className="color-white">Email</TableCell>
                                    <TableCell className="color-white" >Phone</TableCell>
                                    <TableCell className="color-white" >Address</TableCell>
                                    <TableCell className="color-white" >IP</TableCell>
                                    <TableCell className="color-white" >Revenue</TableCell>
                                    <TableCell className="color-white" >N</TableCell>
                                    <TableCell className="color-white" >Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBodyUser
                                userDetailsList={userDetailsList}
                                selectedUsers={selectedUsers}
                                onClickAddUser={onClickAddUser}
                                revokeUser={revokeUser}
                            />
                        </Table>
                    </TableContainer>
                </Box>
            </Grid>
            <CustomAddModal
                open={openNotificaton}
                saveLabel="Skip"
                title="Send Notification"
                maxWidth="sm"
                disableSave
                handleClose={() => setOpenNotificaton(false)}
                onSubmitConfirm={onSubmitNotification}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <p>Selected User: {selectedUsers.length}</p>
                            <Button color="primary" variant="contained" onClick={onSubmitNotification}>SEND</Button>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <textarea
                            style={{
                                minWidth: "100%",
                                maxWidth: "100%",
                                height: "100px",
                                margin: '10px 0px'
                            }}
                            value={notificationText}
                            onChange={e => setNotificationText(e.target.value)}
                            placeholder='Enter Notification'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: 80 }} align="left">S.no</th>
                                    <th style={{ width: 140 }} align="left">Name</th>
                                    <th align="left">Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    selectedUsers.map((item, index) => {
                                        return <tr key={index}>
                                            <td style={{ width: 80 }} align="left">{index + 1}</td>
                                            <td style={{ width: 140 }} align="left">{item.name}</td>
                                            <td align="left">{item.email}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>

                    </Grid>
                </Grid>
            </CustomAddModal>
        </Grid>
    )
}